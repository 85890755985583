/* eslint-disable react-hooks/exhaustive-deps */
import { Component, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Collapse from "@kunukn/react-collapse";
import {
  CHAINS,
  currencyAddresses,
  nativeTokenList,
  supportedStableCoins,
  ZERO_ADDRESS,
} from "../constants";
import notificationConfig from "../config/notificationConfig";
import { getBalanceOfTokens, otcTransferToken } from "../Service/otc_function";
import {
  changeNetwork,
  getAccountaddress,
  metamask_connection,
} from "../Service/metamask_function";
import Loader from "react-loader-spinner";
import Web3 from "web3";
import { Link } from "react-router-dom";
import Media from "../Theme/media-breackpoint";
import { GlobalContext } from "../Context/globalContext";
import ReactLoaderSpinner from "react-loader-spinner";
import { read, utils, writeFile } from "xlsx";

function OTCTransferTokens(props) {
  const [dropDown, setDropDown] = useState(false);
  const [popup01, setPopup01] = useState(false);
  const [popup02, setPopup02] = useState(false);
  const [searchBX, setSearchBX] = useState(false);
  const [chainSelected, setChainSelected] = useState(null);
  const [tokensSymbolToTransfer, setTokensSymbolToTransfer] =
    useState("Select Token...");
  const [tokenToTransfer, setTokenToTransfer] = useState(ZERO_ADDRESS);
  const [tokenTaxProtected, setTokenTaxProtected] = useState(false);
  const [receiverSinMul, setReceiverSinMul] = useState(false);
  const [transferAsSale, setTransferAsSale] = useState(false);
  const [receiverWalletAddress, setReceiverWalletAddress] = useState("");
  const [transferTokensAmount, setTransferTokensAmount] = useState("");
  const [paymentType, setPaymentType] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [confirmatory, setConfirmatory] = useState(ZERO_ADDRESS);
  const [transferLoading, setTransferLoading] = useState(false);
  const [balances, setBalances] = useState({});
  const [tokenListLoading, setTokenListLoading] = useState(true);
  const [tokenList, setTokenList] = useState(null);
  const [receiveWallets, setReceiveWallets] = useState([]);
  const [position, setPosition] = useState(0);
  const globalContext = useContext(GlobalContext);

  useEffect(async () => {
    if (globalContext.tokenList !== null) {
      await setTokenList(globalContext.tokenList);
      setTokenListLoading(false);
    }
  }, [tokenList, globalContext.tokenList]);

  useEffect(async () => {
    if (globalContext.transferToken !== null && globalContext.linkToTransfer) {
      await setChainSelected(globalContext.transferToken.chainId);
      await setTokenToTransfer(globalContext.transferToken.token);
      await setTokensSymbolToTransfer(globalContext.transferToken.symbol);
      globalContext.setLinkToTransfer(false);
      globalContext.setTransferToken(null);
    }
  }, [globalContext.transferToken, globalContext.linkToTransfer]);

  const onToggle02 = (dropDownNew) =>
    setDropDown(dropDown === dropDownNew ? null : dropDownNew);

  const floatOnly = (event) => {
    if (event.shiftKey === true) event.preventDefault();

    var code = event.keyCode;

    if (
      (code >= 48 && code <= 57) ||
      (code >= 96 && code <= 105) ||
      code === 8 ||
      code === 9 ||
      code === 37 ||
      code === 39 ||
      code === 46 ||
      code === 190 ||
      code === 110
    ) {
      // allowed characters
    } else event.preventDefault();

    if (
      event.target.value.indexOf(".") !== -1 &&
      (code === 190 || code === 110)
    )
      event.preventDefault();
  };

  const intOnly = async (event) => {
    if (event.shiftKey === true) event.preventDefault();

    var code = event.keyCode;

    if (
      (code >= 48 && code <= 57) ||
      (code >= 96 && code <= 105) ||
      code === 8 ||
      code === 9 ||
      code === 37 ||
      code === 39 ||
      code === 46
    ) {
      // allowed characters
    } else event.preventDefault();
  };

  const _onChangeRadio = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value === "true" ? true : false,
    });
  };

  const handleInputChange = async (event) => {
    if (tokenToTransfer === ZERO_ADDRESS) {
      notificationConfig.warning("Please select token first!");
      return;
    } else {
      const { value } = event.target;

      if (
        Number(value) >
        Number(
          Number(tokenList[chainSelected][tokenToTransfer].balances[position]) /
            10 ** 18,
        )
      ) {
        notificationConfig.warning("Insufficient balance for transfer!");
        return;
      }
      setTransferTokensAmount(value);
    }
  };

  const handleTransferToken = async () => {
    if (chainSelected === null) {
      notificationConfig.warning("Please select network first!");
      return;
    }
    if (tokenToTransfer === ZERO_ADDRESS) {
      notificationConfig.warning("Please select token first!");
      return;
    }
    if (receiverSinMul) {
      if (receiveWallets.length <= 0) {
        notificationConfig.info("Please Add Proper Receiver Wallet Addresses");
        return 0;
      }
    } else {
      if (
        transferTokensAmount === "" ||
        transferTokensAmount === "0" ||
        transferTokensAmount <= 0
      ) {
        notificationConfig.info("Please Enter Valid Token Amount");
        return 0;
      }
      if (receiverWalletAddress === "0x" || receiverWalletAddress === "") {
        notificationConfig.info("Please Enter Valid Receiver Wallet Address");
        return 0;
      }
    }

    if (tokenTaxProtected) {
      if (
        confirmatory === "0x" ||
        confirmatory === "" ||
        confirmatory === ZERO_ADDRESS
      ) {
        notificationConfig.info("Please Enter Valid Confirmatory Address");
        return 0;
      }

      if (
        Number(transferTokensAmount) >
        (Number(tokenList[chainSelected][tokenToTransfer].balances.total) -
          Number(tokenList[chainSelected][tokenToTransfer].balances[0])) /
          10 ** 18
      ) {
        notificationConfig.info("Insufficient Balance For Restricted Transfer");
        return 0;
      }
    } else {
      // if (Number(transferTokensAmount) > (Number(balances[0]) / 10 ** 18)) {
      //     console.log('============');
      //     console.log(Number(transferTokensAmount));
      //     console.log(balances[0]);
      //     console.log((Number(balances[0]) / 10 ** 18));
      //     notificationConfig.info("Insufficient Balance For Transfer");
      //     return 0;
      // }
    }

    if (transferAsSale) {
      if (paymentAmount === "" || paymentAmount === "0" || paymentAmount <= 0) {
        notificationConfig.info("Please Enter Valid Payment Amount");
        return 0;
      }
      if (paymentType === null) {
        notificationConfig.info("Please Select Payment Type");
        return 0;
      }
    }

    setTransferLoading(true);

    let otcTransferTokenData = {};

    otcTransferTokenData["buyer"] =
      receiverWalletAddress !== ZERO_ADDRESS
        ? receiverWalletAddress
        : ZERO_ADDRESS;
    otcTransferTokenData["tokenSell"] = tokenToTransfer;
    otcTransferTokenData["sellAmount"] = receiverSinMul
      ? "0"
      : Web3.utils.toWei(transferTokensAmount);
    otcTransferTokenData["wantToken"] = transferAsSale
      ? paymentType.token
      : ZERO_ADDRESS;
    otcTransferTokenData["router"] = ZERO_ADDRESS;
    otcTransferTokenData["value"] = Web3.utils.toWei(
      paymentAmount === "" ? "0" : paymentAmount,
    );
    otcTransferTokenData["confirmatory"] = confirmatory;
    otcTransferTokenData["token"] = tokenToTransfer;
    otcTransferTokenData["to"] = receiverWalletAddress;
    otcTransferTokenData["receiverSinMul"] = receiverSinMul;
    otcTransferTokenData["position"] = position;

    if (receiverSinMul) {
      otcTransferTokenData["addresses"] = [];
      otcTransferTokenData["amounts"] = [];
      receiveWallets.map((ele) => {
        otcTransferTokenData["addresses"].push(ele[0]);
        otcTransferTokenData["amounts"].push(
          Web3.utils.toWei(ele[1].toString()),
        );
      });
    }

    otcTransferTokenData["transferType"] = "nonRestrictedTransfer";

    if (transferAsSale) {
      otcTransferTokenData["transferType"] = "transferAsSale";
    } else if (tokenTaxProtected) {
      otcTransferTokenData["transferType"] = "tokenTaxProtected";
    }

    await otcTransferToken(
      otcTransferTokenData,
      (hash) => {},
      async (receipt) => {
        globalContext.fetchTokenList();
        setTransferLoading(false);
        notificationConfig.success("Tokens transferred successfully!");
      },
      async (error) => {
        setTransferLoading(false);
        if (error.code === 4001) {
          notificationConfig.error("Transaction rejected!");
        }
      },
    );
  };

  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 1 });
          let checkedRows = {};
          rows.map((ele, key) => {
            if (
              Web3.utils.isAddress(ele["0"]) ||
              Web3.utils.isAddress(ele["1"])
            ) {
              if (Web3.utils.isAddress(ele["0"]) && !isNaN(ele["1"])) {
                if (checkedRows[ele["0"]]) {
                  checkedRows[ele["0"]] =
                    Number(checkedRows[ele["0"]]) + Number(ele["1"]);
                } else {
                  checkedRows[ele["0"]] = "0";
                  checkedRows[ele["0"]] =
                    Number(checkedRows[ele["0"]]) + Number(ele["1"]);
                }
                // checkedRows.push(ele)
              } else if (Web3.utils.isAddress(ele["1"]) && !isNaN(ele["0"])) {
                let add = ele["1"];
                ele["1"] = ele["0"];
                ele["0"] = add;
                if (checkedRows[ele["0"]]) {
                  checkedRows[ele["0"]] =
                    Number(checkedRows[ele["0"]]) + Number(ele["1"]);
                } else {
                  checkedRows[ele["0"]] = "0";
                  checkedRows[ele["0"]] =
                    Number(checkedRows[ele["0"]]) + Number(ele["1"]);
                }
                // checkedRows.push(ele)
              } else {
                notificationConfig.warning("Some data discarded!");
                return;
              }
            } else {
              notificationConfig.warning("Some data discarded!");
              return;
            }
          });
          setReceiveWallets(Object.entries(checkedRows));
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <>
      <Tabs className="v2">
        <a className="active">
          <i className="fas fa-exclamation-circle"></i> How it works
        </a>
        <span>|</span>
        {/* <a>Tax protection</a> */}
      </Tabs>
      {/* <TabBox>
                    <a className='close'><i class="fas fa-times"></i></a>
                    <h5>How it works</h5>
                    <p>The transfer function works similarly to a private peer-to-peer transaction, allowing users to send tokens to a specific wallet address or set of wallet addresses. These transfers can be made for free or in exchange for receiving payment using native tokens like ETH or BNB. Receivers of tokens through transfer are subject to the same vesting terms that apply to the user transferring the tokens.</p>
                </TabBox> */}
      {/* <PlacOrdMBX>
                    <DSTitle01>
                        <span>Transfer Tokens <span data-tip='Tokens transferred through the Dumper Shield remain behind the Dumper Shield for the receiver, regardless of method utilized.' className="helpIco"><i className="fas fa-question-circle"></i></span> </span>
                    </DSTitle01>
                </PlacOrdMBX> */}
      <FormMBX01>
        <FormSbxLabel>Chain on want to transfer token</FormSbxLabel>
        <FormSbxContent>
          <DDMenu onClick={() => onToggle02(3)}>
            {chainSelected !== null
              ? CHAINS[Web3.utils.toHex(chainSelected)]
              : "Select Network..."}
            <div className="DDContainer">
              <Collapse
                className={"collapse " + (dropDown === 3 ? "active" : "")}
                isOpen={dropDown === 3}
                onChange={({ state }) => {}}
                onInit={({ state }) => {}}
              >
                <div className="DDSubContainer">
                  {!tokenListLoading ? (
                    Object.entries(CHAINS).map((ele) => {
                      return (
                        <button
                          onClick={() => {
                            setChainSelected(Web3.utils.toDecimal(ele[0]));
                            setTokenToTransfer(ZERO_ADDRESS);
                            setTokensSymbolToTransfer("Select Token...");
                          }}
                        >
                          {ele[1]}
                        </button>
                      );
                    })
                  ) : (
                    <ReactLoaderSpinner
                      type="Bars"
                      color="#fff"
                      height={20}
                      width={40}
                    />
                  )}

                  {/* <button>BNB</button>
                                        <button>ETH</button>
                                        <button>FTM</button> */}
                </div>
              </Collapse>
            </div>
          </DDMenu>
        </FormSbxContent>
      </FormMBX01>
      <FormMBX01>
        <FormSbxLabel>Tokens symbol to transfer</FormSbxLabel>
        <FormSbxContent>
          <DDMenu onClick={() => onToggle02(1)}>
            {tokensSymbolToTransfer}
            <div className="DDContainer">
              <Collapse
                className={"collapse " + (dropDown === 1 ? "active" : "")}
                isOpen={dropDown === 1}
                onChange={({ state }) => {}}
                onInit={({ state }) => {}}
              >
                <div className="DDSubContainer">
                  {!tokenListLoading && chainSelected !== null ? (
                    Object.entries(tokenList[chainSelected]).map((ele) => {
                      // if(ele[1].canTransfer)
                      return (
                        <button
                          onClick={() => {
                            setTokenToTransfer(ele[1].token);
                            setTokensSymbolToTransfer(ele[1].symbol);
                          }}
                        >
                          {ele[1].symbol}
                        </button>
                      );
                    })
                  ) : (
                    <ReactLoaderSpinner
                      type="Bars"
                      color="#fff"
                      height={20}
                      width={40}
                    />
                  )}

                  {/* <button>BNB</button>
                                        <button>ETH</button>
                                        <button>FTM</button> */}
                </div>
              </Collapse>
            </div>
          </DDMenu>
        </FormSbxContent>
      </FormMBX01>

      <FormMBX01>
        <FormSbxLabel>
          Position
          <span
            data-tip="Tax protection requests must be verified by other Dumper Shield users. Consult a local accountant, this is not tax advice."
            className="helpIco"
          >
            <i className="fas fa-question-circle"></i>
          </span>
        </FormSbxLabel>
        <FormSbxContent>
          <DDMenu className="noBorder ver2">
            <div className="md-radio md-radio-inline" style={{ width: "30%" }}>
              <input
                type="radio"
                defaultChecked
                id="radioty147"
                name="positionTransfer"
                value={position === 0}
                onChange={(e) => setPosition(0)}
              />
              <label htmlFor="radioty147">
                1st
                <span data-tip="1st Position" className="helpIco">
                  <i className="fas fa-question-circle"></i>
                </span>
              </label>
            </div>
            <div className="md-radio md-radio-inline" style={{ width: "30%" }}>
              <input
                type="radio"
                id="radioty148"
                name="positionTransfer"
                value={position === 1}
                onChange={(e) => setPosition(1)}
              />
              <label htmlFor="radioty148">
                2nd
                <span data-tip="2nd Position" className="helpIco">
                  <i className="fas fa-question-circle"></i>
                </span>
              </label>
            </div>
            <div className="md-radio md-radio-inline" style={{ width: "30%" }}>
              <input
                type="radio"
                id="radioty149"
                name="positionTransfer"
                value={position === 2}
                onChange={(e) => setPosition(2)}
              />
              <label htmlFor="radioty149">
                3rd
                <span data-tip="3rd Position" className="helpIco">
                  <i className="fas fa-question-circle"></i>
                </span>
              </label>
            </div>
          </DDMenu>
        </FormSbxContent>
      </FormMBX01>

      {/* <FormMBX01>
                    <FormSbxLabel>Type of tokens to transfer<span data-tip='Tax protection requests must be verified by other Dumper Shield users. Consult a local accountant, this is not tax advice.' className="helpIco"><i className="fas fa-question-circle"></i></span></FormSbxLabel>
                    <FormSbxContent >
                        <DDMenu className="noBorder ver2">
                            <div className="md-radio md-radio-inline">
                                <input type="radio" defaultChecked id="radioty145" name="tokenTaxProtected" value="false" onChange={(e) => setTokenTaxProtected(false)} /><label htmlFor="radioty145">
                                    Non tax protected tokens<span data-tip='Non tax protected tokens are immediately available for you to access to and use to transfer, sell, freeze, or any other option allowed under the Dumper Shield' className="helpIco"><i className="fas fa-question-circle"></i></span>
                                </label>
                            </div>
                            <div className="md-radio md-radio-inline">
                                <input type="radio" id="radioty146" name="tokenTaxProtected" value="true" onChange={(e) => setTokenTaxProtected(true)} /><label htmlFor="radioty146">
                                    Tax protected tokens<span data-tip='Tax protected token require another person to help access your token and an accountant should be consulted' className="helpIco"><i className="fas fa-question-circle"></i></span>
                                </label>
                            </div>
                        </DDMenu>
                    </FormSbxContent>
                </FormMBX01> */}
      {!receiverSinMul ? (
        <FormMBX01>
          <FormSbxLabel>Tokens amount to transfer</FormSbxLabel>
          <FormSbxContent>
            <DDMenu className="formBox">
              <input
                type="text"
                name="transferTokensAmount"
                onChange={(e) => handleInputChange(e)}
                value={transferTokensAmount}
                onKeyDown={(e) => floatOnly(e)}
                autoComplete="off"
              />
            </DDMenu>
            <p className="small">
              Available balance :
              {tokensSymbolToTransfer !== "Select Token..." &&
              tokenToTransfer !== ZERO_ADDRESS &&
              chainSelected !== null ? (
                <span>
                  {" " +
                    (
                      Number(
                        tokenList[chainSelected][tokenToTransfer].balances[
                          position
                        ],
                      ) /
                      10 ** 18
                    ).toFixed(4)}{" "}
                  {tokensSymbolToTransfer}
                </span>
              ) : (
                " N/A"
              )}
            </p>
          </FormSbxContent>
        </FormMBX01>
      ) : null}

      <FormMBX01>
        <FormSbxLabel>Receiver</FormSbxLabel>
        <FormSbxContent>
          <DDMenu className="noBorder ver2">
            <div className="md-radio md-radio-inline">
              <input
                type="radio"
                defaultChecked
                id="radio145"
                name="receiverSinMul"
                value="false"
                onChange={(e) => setReceiverSinMul(false)}
              />
              <label htmlFor="radio145">Single wallet</label>
            </div>
            <div className="md-radio md-radio-inline">
              <input
                type="radio"
                id="radio146"
                name="receiverSinMul"
                value="true"
                onChange={(e) => {
                  setReceiverSinMul(true);
                  setTransferAsSale(false);
                }}
              />
              <label htmlFor="radio146">Multi wallets</label>
            </div>
          </DDMenu>
        </FormSbxContent>
      </FormMBX01>

      {receiverSinMul ? (
        <FormMBX01 className="verticalTop">
          <FormSbxLabel className="labelTop">
            Upload multi wallets at excel format
            <span
              data-tip="Wallet address where you want the tokens to transfer to"
              className="helpIco"
            >
              <i className="fas fa-question-circle"></i>
            </span>
          </FormSbxLabel>
          <FormSbxContent>
            <UploadBtn>
              {/* <input type="file" name="receiverWalletAddress" onChange={(e)=> setReceiverWalletAddress(e.target.value)} autoComplete="off" /> */}
              <input
                type="file"
                name="file"
                className="custom-file-input"
                id="inputGroupFile"
                required
                onChange={handleImport}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              {/* <label className="custom-file-label" htmlFor="inputGroupFile">Choose file</label> */}
              <span>
                <i className="fas fa-file-excel"></i> Upload Excel File
              </span>
            </UploadBtn>
            <UploadNote>
              Upload an excel file with 2 columns, column A for wallet address
              and column B for token amounts{" "}
            </UploadNote>
            {receiveWallets.length > 0 ? (
              <TableMain>
                <table
                  className="border-cell"
                  width="100%"
                  border="0"
                  cellSpacing="0"
                  cellPadding="20"
                >
                  <thead>
                    <tr>
                      <th width="30px" align="left" valign="middle" scope="col">
                        No
                      </th>
                      <th width="50%" align="left" valign="middle" scope="col">
                        Wallet address
                      </th>
                      <th width="50%" align="left" valign="middle" scope="col">
                        Amount of tokens{" "}
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {receiveWallets.map((ele, key) => {
                      return (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td className="color-blue">
                            <span>{ele["0"]}</span>
                          </td>
                          <td>
                            {ele["1"]}{" "}
                            <a
                              className="removeRow"
                              onClick={() => {
                                setReceiveWallets(
                                  receiveWallets.filter((ele, keyInside) => {
                                    if (keyInside !== key) return ele;
                                  }),
                                );
                              }}
                            >
                              <i className="fas fa-times"></i>
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </TableMain>
            ) : null}
            <TableBottom>
              {/* <h5>My Multi Wallets.xlsx <a className='color-green'>Change</a></h5> */}
              <Tnav>
                Page
                <SelectCustom>
                  <select>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select>
                </SelectCustom>
                result 1-10 of 300
              </Tnav>
            </TableBottom>
          </FormSbxContent>
        </FormMBX01>
      ) : (
        <FormMBX01>
          <FormSbxLabel>
            Receiver wallet address
            <span
              data-tip="Wallet address where you want the tokens to transfer to"
              className="helpIco"
            >
              <i className="fas fa-question-circle"></i>
            </span>
          </FormSbxLabel>
          <FormSbxContent>
            <DDMenu className="formBox">
              <input
                type="text"
                name="receiverWalletAddress"
                onChange={(e) => setReceiverWalletAddress(e.target.value)}
                autoComplete="off"
              />
            </DDMenu>
            <strong className="note">
              Receivers of tokens through transfer are subject to the same
              vesting terms that apply to the user transferring.
            </strong>
          </FormSbxContent>
        </FormMBX01>
      )}

      {/* {tokenTaxProtected ?
                    (<FormMBX01>
                        <FormSbxLabel>To transfer restricted tokens under tax protection, please provide confirmatory wallet address<span data-tip='Tax protected transfers require confirmation' className="helpIco"><i className="fas fa-question-circle"></i></span></FormSbxLabel>
                        <FormSbxContent >
                            <DDMenu className="formBox">
                                <input type="text" name="confirmatory" onChange={(e)=>setConfirmatory(e.target.value)} autoComplete="off" />
                            </DDMenu>
                        </FormSbxContent>
                    </FormMBX01>)
                    : null} */}
      <FormMBX01>
        <FormSbxLabel> </FormSbxLabel>
        <FormSbxContent>
          {localStorage.getItem("account") !== null ? (
            chainSelected !== null &&
            Web3.utils.toHex(chainSelected) !== globalContext.chainId ? (
              <button
                className="PlaOrBTN01"
                onClick={() => {
                  changeNetwork(Web3.utils.toHex(chainSelected));
                }}
              >
                CHANGE NETWORK
              </button>
            ) : (
              <button
                className="PlaOrBTN01"
                onClick={() => handleTransferToken()}
              >
                {transferLoading ? (
                  <Loader
                    type="ThreeDots"
                    color="#fff"
                    height={15}
                    width={80}
                  />
                ) : tokenTaxProtected ? (
                  "TRANSFER NON TAX PROTECTED TOKENS"
                ) : (
                  "TRANSFER"
                )}
              </button>
            )
          ) : (
            <button
              className="PlaOrBTN01"
              onClick={() =>
                metamask_connection(
                  localStorage.getItem("account"),
                  "ahrefClick",
                )
              }
            >
              CONNECT WALLET
            </button>
          )}
        </FormSbxContent>
      </FormMBX01>
      {/* <History>
                    <h4>History Transfers</h4>
                    <TableMain className='height1'>
                        <table className='border-cell' width="100%" border="0" cellSpacing="0" cellPadding="20" >
                            <thead>
                                <tr>
                                    <th align="left" valign="middle" scope="col">My Active Offers</th>
                                    <th align="left" valign="middle" scope="col">History Purchase</th>
                                    <th align="left" valign="middle" scope="col">History selling  </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>JNTR <i className="fas fa-angle-right color-dull"></i> <span className="color-dull">ETH</span> </td>
                                    <td>Apr 2, 2022</td>
                                    <td>Apr 2, 2022</td>
                                </tr>
                                <tr>
                                    <td>JNTR <i className="fas fa-angle-right color-dull"></i> <span className="color-dull">ETH</span> </td>
                                    <td>Apr 2, 2022</td>
                                    <td>Apr 2, 2022</td>
                                </tr>
                                <tr>
                                    <td>JNTR <i className="fas fa-angle-right color-dull"></i> <span className="color-dull">ETH</span> </td>
                                    <td>Apr 2, 2022</td>
                                    <td>Apr 2, 2022</td>
                                </tr>
                            </tbody>
                        </table>
                    </TableMain>
                </History> */}
      {/* <PlacOrdMBX>
                    <DSTitle01>
                        <span>Transfer Tokens <span data-tip='Tokens transferred through the Dumper Shield remain behind the Dumper Shield for the receiver, regardless of method utilized.' className="helpIco"><i className="fas fa-question-circle"></i></span> </span>
                    </DSTitle01>
                </PlacOrdMBX>
                <FormMBX01>
                    <FormSbxLabel>Token symbol to transfer</FormSbxLabel>
                    <FormSbxContent >
                        <DDMenu onClick={() => onToggle02(1)}>
                            {tokensSymbolToTransfer}
                            <div className="DDContainer">
                                <Collapse className={"collapse " + (dropDown === 1 ? "active" : "")}
                                    isOpen={dropDown === 1}
                                    onChange={({ state }) => {  }}
                                    onInit={({ state }) => {  }}>
                                    <div className="DDSubContainer">
                                        {
                                            !tokenListLoading ?
                                                Object.entries(tokenList).map((ele) => {
                                                    if(ele[1].canTransfer)
                                                        return <button onClick={() => { setTokenToTransfer(ele[1].token); setTokensSymbolToTransfer(ele[1].symbol);} }>{ele[1].symbol}</button>
                                                })
                                                : <ReactLoaderSpinner type="Bars" color="#fff" height={20} width={40} /> 
                                        }
                                    </div>
                                </Collapse>
                            </div>
                        </DDMenu>
                    </FormSbxContent>
                </FormMBX01>
                <FormMBX01>
                    <FormSbxLabel>Type of tokens to transfer<span data-tip='Tax protection requests must be verified by other Dumper Shield users. Consult a local accountant, this is not tax advice.' className="helpIco"><i className="fas fa-question-circle"></i></span></FormSbxLabel>
                    <FormSbxContent >
                        <DDMenu className="noBorder ver2">
                            <div className="md-radio md-radio-inline">
                                <input type="radio" defaultChecked id="radioty145" name="tokenTaxProtected" value="false" onChange={(e) => setTokenTaxProtected(false)} /><label htmlFor="radioty145">
                                    Non tax protected tokens<span data-tip='Non tax protected tokens are immediately available for you to access to and use to transfer, sell, freeze, or any other option allowed under the Dumper Shield' className="helpIco"><i className="fas fa-question-circle"></i></span>
                                </label>
                            </div>
                            <div className="md-radio md-radio-inline">
                                <input type="radio" id="radioty146" name="tokenTaxProtected" value="true" onChange={(e) => setTokenTaxProtected(true)} /><label htmlFor="radioty146">
                                    Tax protected tokens<span data-tip='Tax protected token require another person to help access your token and an accountant should be consulted' className="helpIco"><i className="fas fa-question-circle"></i></span>
                                </label>
                            </div>
                        </DDMenu>
                    </FormSbxContent>
                </FormMBX01>
                {!receiverSinMul ?
                <FormMBX01>
                    <FormSbxLabel>Tokens amount to transfer</FormSbxLabel>
                    <FormSbxContent >
                        <DDMenu className="formBox">
                            <input type="text" name="transferTokensAmount" onChange={(e) => handleInputChange(e)} value={transferTokensAmount} onKeyDown={(e) => floatOnly(e)} autoComplete="off" />
                        </DDMenu>
                        <p className="small">Available balance : 
                            {tokensSymbolToTransfer !== "Select Token..." && tokenToTransfer !== ZERO_ADDRESS ?
                            <span>
                                 {" "+(Number(tokenList[tokenToTransfer].balances[0]) / 10 ** 18).toFixed(4)} {tokensSymbolToTransfer}
                            </span>
                            : " N/A" }
                        </p>
                    </FormSbxContent>
                </FormMBX01> : null}
                <FormMBX01>
                    <FormSbxLabel>Receiver wallet address<span data-tip='Wallet address where you want the tokens to transfer to' className="helpIco"><i className="fas fa-question-circle"></i></span></FormSbxLabel>
                    <FormSbxContent >
                        <DDMenu className="formBox">
                            <input type="text" name="receiverWalletAddress" onChange={(e)=> setReceiverWalletAddress(e.target.value)} autoComplete="off" />
                        </DDMenu>
                    </FormSbxContent>
                </FormMBX01>
                <FormMBX01>
                    <FormSbxLabel>Type of transfer</FormSbxLabel>
                    <FormSbxContent >
                        <DDMenu className="noBorder ver2">
                            <div className="md-radio md-radio-inline">
                                <input type="radio" id="radio147" name="transferAsSale" value="true" checked={transferAsSale} onChange={(e) => setTransferAsSale(true)} disabled={receiverSinMul}/><label htmlFor="radio147">
                                    Transfer as a sale
                                </label>
                            </div>
                            <div className="md-radio md-radio-inline">
                                <input type="radio" id="radio148" name="transferAsSale" value="false" checked={!transferAsSale} onChange={(e) => setTransferAsSale(false)} /><label htmlFor="radio148">
                                    Transfer for free
                                </label>
                            </div>
                        </DDMenu>
                    </FormSbxContent>
                </FormMBX01>
                <FormMBX01>
                    <FormSbxLabel className="mt-0">To transfer restricted tokens under tax protection, 
please provide confirmatory wallet address<span data-tip='Wallet address where you want the tokens to transfer to' className="helpIco"><i className="fas fa-question-circle"></i></span></FormSbxLabel>
                    <FormSbxContent >
                        <DDMenu className="formBox">
                            <input type="text" name="receiverWalletAddress" onChange={(e)=> setReceiverWalletAddress(e.target.value)} autoComplete="off" />
                        </DDMenu>
                    </FormSbxContent>
                </FormMBX01>
                <FormMBX01>
                    <FormSbxLabel> </FormSbxLabel>
                    <FormSbxContent >
                        {localStorage.getItem("account") !== null ?
                            <button className="PlaOrBTN01" onClick={() => handleTransferToken("BSC")}>
                                {transferLoading ?
                                    <Loader type="ThreeDots" color="#fff" height={15} width={80} />
                                    :
                                    tokenTaxProtected ? "TRANSFER TAX PROTECTED TOKENS" : "TRANSFER TAX PROTECTED TOKENS"
                                }
                            </button>
                            :
                            (
                                <button className="PlaOrBTN01" onClick={() => metamask_connection(
                                    localStorage.getItem("account"),
                                    "ahrefClick"
                                )}>
                                    CONNECT WALLET
                                </button>
                            )}
                    </FormSbxContent>
                </FormMBX01> */}
    </>
  );
}

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const DSTitle01 = styled(FlexDiv)`
  justify-content: space-between;
  font: 700 36px/40px "Kanit", arial;
  color: #00f02b;
  .DSrPart {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    font-weight: 700;
  }
  ${Media.xs} {
    font: 700 28px/34px "Kanit", arial;
  }
  .helpIco {
    top: -20px;
    right: 5px;
  }
`;

const FormMBX01 = styled(FlexDiv)`
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 20px;
  ${Media.sm} {
    display: block;
  }
  &.verticalTop {
    align-items: flex-start;
  }
`;

const FormSbxLabel = styled(FlexDiv)`
  display: -webkit-inline-box;
  width: 30%;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 700;
  align-self: flex-start;
  margin: 20px 0 0 0;
  ${Media.sm} {
    width: 100%;
    margin-bottom: 10px;
  }
  &.mt-0 {
    margin-top: 0;
  }
`;

const FormSbxContent = styled(FlexDiv)`
  width: 70%;
  justify-content: flex-start;
  ${Media.sm} {
    width: 100%;
  }
  p {
    font-size: 11px;
    font-weight: normal;
    color: #8e9195;
    text-align: right;
    margin-top: 10px;
    margin-bottom: 0px;
    width: 100%;
    span {
      color: #fff;
    }
  }
  .md-radio label {
    font-size: 16px;
    color: #fff;
    font-weight: 400;
  }
  .md-radio label:after {
    background-color: #00f02b;
  }

  .md-radio label:before,
  .md-radio input[type="radio"]:checked + label:before {
    border: 2px solid #5a5e67;
    left: -2px;
    top: -2px;
    width: 24px;
    height: 24px;
  }

  .PlaOrBTN01 {
    min-width: 344px;
    font-size: 16px;
    font-weight: 700;
    height: 60px;
    color: #fff;
    border-radius: 10px;
    border: 2px solid #00ef2b;
    box-shadow: 0 0 10px #00ef2b;
    padding-left: 10px;
    padding-right: 10px;
    :hover {
      background-color: #00ef2b;
    }
    ${Media.sm} {
      min-width: auto;
      width: 100%;
    }
  }
  .BoxInfo {
    color: #c32b2d;
    font-size: 12px;
    margin: 7px 0 0 0;
  }
  .note {
    color: #c32b2d;
    font-size: 12px;
    font-weight: normal;
    margin: 8px 0 3px 0;
  }
`;

const DDMenu = styled(FlexDiv)`
  justify-content: flex-start;
  position: relative;
  height: 60px;
  border-radius: 10px;
  border: 2px solid #5a5e67;
  font-size: 16px;
  font-weight: 400;
  padding: 0 15px;
  i {
    max-width: 80px;
    margin-right: 10px;
  }
  :after {
    content: "";
    border-width: 6px;
    border-style: solid;
    border-bottom: 0px;
    transition: 0.5s ease all;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    right: 16px;
    top: 25px;
    transform: rotate(0deg);
    transform-origin: center;
  }
  &.active {
    :after {
      transform: rotate(180deg);
    }
  }
  &.formBox {
    input {
      width: 100%;
      height: 52px;
      padding: 6px 0;
      background-color: transparent;
      border: 0px;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }
    :after {
      display: none;
    }
  }
  &.noBorder {
    :after {
      display: none;
    }
    border: 0px;
  }

  .DDContainer {
    width: calc(100% + 4px);
    z-index: 10;
    position: absolute;
    left: -2px;
    top: 90%;
  }
  .DDSubContainer {
    width: 100%;
    color: white;
    border-radius: 10px;
    background-color: #0b0c0f;
    border: 2px solid #5a5e67;
    padding: 10px;
    border-top: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin: 0 0 8px 0;

    button {
      width: 100%;
      color: #fff;
      border-radius: 8px;
      text-align: left;
      display: block;
      padding: 10px 6px;
      border: none;
      outline: none;
      :hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }
  &.ver2 {
    ${Media.xs} {
      height: auto;
    }
  }
  .md-radio.md-radio-inline {
    ${Media.xs} {
      width: 100%;
      display: block;
    }
  }
`;

const PlacOrdMBX = styled(FlexDiv)`
  padding: 50px 0;
  color: #fff;
  border-top: 1px solid #1e2127;
  ${Media.xs} {
    padding: 30px 0;
  }
`;

const History = styled.div`
  padding: 75px 0 78px;
  margin-bottom: 0;
  border-bottom: 1px solid #1d2025;
  h4 {
    font-size: 18px;
    width: 100%;
    margin: 0 0 45px;
  }
`;
const TableMain = styled.div`
  position: relative;
  overflow: auto;
  width: 100%;
  max-width: 100%;
  max-height: 500px;
  .UploadNote-efAIMq + & {
    margin-top: 15px;
  }
  &::-webkit-scrollbar {
    width: 10px;
    background: #545861;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #0a0c0e;
    /* outline: 3px solid #545861; */
    box-shadow: inset 0 0 0 2px #545861;
  }
  &.height1 {
    max-height: 427px;
  }
  &.height2 {
    max-height: 281px;
  }
  &.height3 {
    max-height: 750px;
  }
  table {
    background-color: #000000;
    width: 100%;
    max-width: 100%;
    border: 1px solid #1e2127;
    border-collapse: separate;
    thead {
      th {
        position: sticky;
        top: 0;
        left: 0;
        border-top: 1px solid #1e2127;
        border-bottom: 1px solid #1e2127;
        border-right: 1px solid #1e2127;
        &:last-child {
          border-right: 0;
        }
      }
    }
    td {
      border-top: 1px solid #1e2127;
      position: relative;
      .removeRow {
        float: right;
        cursor: pointer;
        color: #c32b2d;
        opacity: 0;
      }
    }
    &.border-cell {
      td {
        border-bottom: 0 solid #1e2127;
        border-right: 1px solid #1e2127;
        &:last-child {
          border-right: 0;
        }
      }
    }
  }
  table tr td {
    padding: 10px 18px;
    color: #fff;
    height: 60px;
    &.bor01 {
      border-right: 5px solid #545861;
    }
    &.bor02 {
      color: #4b4bff;
    }
    ${Media.md} {
      padding: 10px 10px;
    }
    .fa-circle {
      font-size: 8px;
      line-height: 16px;
      vertical-align: top;
    }
  }
  table tr:hover td {
    background-color: #000;
    .removeRow {
      opacity: 1;
    }
  }
  table tr th {
    color: #9a9a9a;
    position: relative;
    padding: 12px 18px;
    height: 60px;
    padding-right: 27px;
    a {
      color: #9a9a9a;
      font-size: 18px;
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
    &:nth-last-child(01) {
    }
    &:nth-child(01) {
    }
    &.bor01 {
      border-right: 5px solid #545861;
    }
  }
  .color-dull {
    color: #9a9a9a;
  }
  .color-green {
    color: #00f02b;
  }
  .color-red {
    color: #c32b2d;
  }
  .color-yellow {
    color: #e4a029;
  }
  .color-blue {
    color: #3131ff;
  }
  .btn01 {
    width: 102px;
    font-weight: 700;
    height: 40px;
    border-radius: 5px;
    color: #00f02b;
    display: inline-block;
    text-align: center;
    line-height: 36px;
    border: 2px solid transparent;
    margin: 0px auto;
    &:hover {
      border: 2px solid #00f02b;
      box-shadow: 0 0 10px #00f02b;
    }
  }
  .btn02 {
    width: 102px;
    font-weight: 700;
    height: 40px;
    border-radius: 5px;
    color: #c32b2d;
    display: inline-block;
    text-align: center;
    line-height: 36px;
    border: 2px solid transparent;
    &:hover {
      border: 2px solid #c32b2d;
      box-shadow: 0 0 10px #c32b2d;
    }
  }
  .btn-red {
    color: #53575d;
    font-size: 25px;
    cursor: pointer;
    margin: 0px auto;
  }
  .btn-red:hover {
    color: #c32b2d;
  }
`;
const TableBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #9a9a9a;
  padding: 16px 0 0;
  width: 100%;
  h5 {
    font-size: 12px;
    font-weight: bold;
    margin: 0;
    a {
      margin-left: 15px;
    }
  }
  .color-green {
    color: #00f02b;
  }
`;
const Tnav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #9a9a9a;

  p {
    color: #9a9a9a;
  }
  a {
    color: #aaa;
    margin: 0 10px;
  }
  a:hover {
    color: #91dc27;
  }
`;
const SelectCustom = styled.div`
  display: inline-block;
  position: relative;
  margin: 0 10px;
  select {
    background: #010202;
    min-width: 80px;
    color: #9a9a9a;
    font-size: 12px;
    border: 0;
    height: 30px;
    border-radius: 4px;
    padding: 0 10px;
    appearance: none;
  }
  &:after {
    content: "\f0d7";
    font-family: "Font Awesome 5 Free";
    color: #aaa;
    font-weight: 900;
    position: absolute;
    right: 5px;
    top: 8px;
  }
`;
const UploadBtn = styled.div`
  background: #595d67;
  width: 346px;
  height: 60px;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  text-align: center;
  line-height: 60px;
  cursor: pointer;
  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  span {
    font-size: 16px;
    font-weight: bold;
    i {
      margin-right: 8px;
    }
  }
`;
const UploadNote = styled.div`
  font-size: 14px;
  color: #c32b2d;
  width: auto;
  padding: 0 25px;
`;
const SecondTitle = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column;
  padding: 23px 0 23px;
  .DSrPart {
    display: flex;
    align-items: center;
    margin: 0 0 14px auto;
  }
`;
const Tabs = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 25px;
  align-items: center;
  margin-top: 48px;
  a {
    font-weight: bold;
    &.active {
      color: #00f02b;
    }
    &.active.color-red {
      color: #c32b2d;
    }
  }
  span {
    margin: 0 6px;
    color: #9a9a9a;
  }
  div {
    margin-left: auto;
    color: #9a9a9a;
  }
  ${Media.sm} {
    margin-top: 0;
    flex-flow: wrap;
    div {
      width: 100%;
      margin-top: 16px;
    }
  }
  &.v2 {
    margin-bottom: 48px;
    a {
      color: #5a5e67;
      font-weight: bold;
      &.active {
        color: #fff;
      }
    }
  }
`;
const TabBox = styled.div`
  background: #000;
  border: 1px solid #1e2127;
  padding: 30px 28px;
  position: relative;
  margin-bottom: 48px;
  h5 {
    font-size: 21px;
    font-weight: 700;
    margin: 0 0 25px;
  }
  p {
    font-size: 18px;
    line-height: 30px;
    margin: 0 0 36px;
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    background: #1e2127;
    font-size: 16px;
    cursor: pointer;
  }
`;

export default OTCTransferTokens;
