/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import new_token from "./../Assets/images/new_token.png";
import {
  changeNetwork,
  metamask_connection,
} from "../Service/metamask_function";
import { otcWithdrawTokens, otcTransferToken } from "../Service/otc_function";
import notificationConfig from "../config/notificationConfig";
import Media from "../Theme/media-breackpoint";
import { GlobalContext } from "../Context/globalContext";
import ReactLoaderSpinner from "react-loader-spinner";
import Web3 from "web3";

function TokenDetails(props) {
  let history = useHistory();
  const [withdrawing, setWithdrawing] = useState(false);
  const [transfer, setTransfer] = useState(false);
  const globalContext = useContext(GlobalContext);

  const handleWithdrawTokens = async (token, balance, chainId) => {
    if (Web3.utils.toHex(chainId) !== globalContext.chainId) {
      await changeNetwork(chainId);
      return 0;
    }
    if (balance === "" || balance === "0" || balance <= 0) {
      notificationConfig.warning("Insufficient withdraw amount!");
      return 0;
    }

    setWithdrawing(true);

    let otcWithdrawTokensData = {};

    otcWithdrawTokensData["token"] = token;
    otcWithdrawTokensData["amount"] = balance;

    await otcWithdrawTokens(
      otcWithdrawTokensData,
      (hash) => {},
      async (receipt) => {
        globalContext.fetchTokenList();
        setWithdrawing(false);
        notificationConfig.success("Tokens Withdraw Successfully!");
      },
      async (error) => {
        setWithdrawing(false);
        if (error.code === 4001) {
          notificationConfig.error("Transaction rejected!");
        }
      },
    );
  };

  return (
    <>
      {Number(props.ele[1].mainBalance) / 10 ** 18 > 0 ||
      Number(props.ele[1].balances[0]) / 10 ** 18 > 0 ? (
        <BtnRow>
          <button key={props.ele[1].token}>
            <i className="ImgBX">
              {" "}
              <img
                src={
                  props.ele[1].logoURI !== "" ? props.ele[1].logoURI : new_token
                }
              />{" "}
            </i>
            <VuttinRight>
              <div className="BTNText">
                <DFlex>
                  {props.ele[1].symbol}
                  <p>[${props.ele[1].price}]</p>
                </DFlex>
                <DFlex2>
                  Protected due date: <p>{props.ele[1].dsUnlockDateUTC}</p>
                </DFlex2>
                <DFlex2>
                  Protected tokens
                  <span data-html="true" data-tip="test" className="helpIco">
                    <i className="fas fa-question-circle"></i>
                  </span>{" "}
                  :{" "}
                  <p>
                    {props.ele[1].canRedeem
                      ? 0
                      : Number(props.ele[1].balances[0]) / 10 ** 18}
                  </p>
                </DFlex2>
                <DFlex2>
                  OTC listing
                  <span data-html="true" data-tip="test" className="helpIco">
                    <i className="fas fa-question-circle"></i>
                  </span>{" "}
                  :{" "}
                  <p>
                    {props.otcPairs.filter((pair) =>
                      pair.includes(props.ele[1].symbol),
                    ).length > 0
                      ? props.otcPairs.filter((pair) =>
                          pair.includes(props.ele[1].symbol),
                        ).length
                      : 0}
                  </p>
                </DFlex2>
                <DFlex2>
                  Unprotected tokens
                  <span data-html="true" data-tip="test" className="helpIco">
                    <i className="fas fa-question-circle"></i>
                  </span>{" "}
                  :{" "}
                  <p>
                    {props.ele[1].canRedeem
                      ? Number(props.ele[1].balances[0]) / 10 ** 18
                      : 0}
                  </p>
                </DFlex2>
                <DFlex2>
                  Total value
                  <span data-html="true" data-tip="test" className="helpIco">
                    <i className="fas fa-question-circle"></i>
                  </span>{" "}
                  : <p>${props.ele[1].mainBalance}</p>
                </DFlex2>
                <DFlex2>
                  Tax protected
                  <span data-html="true" data-tip="test" className="helpIco">
                    <i className="fas fa-question-circle"></i>
                  </span>{" "}
                  : <p>0</p>
                </DFlex2>
                <LinksOuter>
                  {/* <a href="#" className='green'>Deposit</a> */}

                  {props.ele[1].canDeposit ? (
                    <a
                      href="#"
                      className="green"
                      onClick={() => {
                        props.handleDepositTokens(props.ele[1]);
                      }}
                    >
                      Deposit
                    </a>
                  ) : null}

                  {props.ele[1].canRedeem ? (
                    withdrawing ? (
                      <ReactLoaderSpinner
                        type="ThreeDots"
                        color="#fff"
                        height={15}
                        width={80}
                      />
                    ) : (
                      <a
                        href="#"
                        className="red"
                        onClick={() => {
                          handleWithdrawTokens(
                            props.ele[1].token,
                            props.ele[1].balances[0],
                            props.ele[1].chainId,
                          );
                        }}
                      >
                        Redeem
                      </a>
                    )
                  ) : (
                    <a href="#" className="red">
                      No vested tokens available
                    </a>
                  )}
                  {/* {props.ele[1].canTransfer ? (
                    transfer ? (
                      <ReactLoaderSpinner
                        type="ThreeDots"
                        color="#fff"
                        height={15}
                        width={80}
                      />
                    ) : (*/}
                  <a
                    href="#"
                    className="white"
                    onClick={() => {
                      globalContext.setTransferToken(props.ele[1]);
                      globalContext.setLinkToTransfer(true);
                      props.setDeactive();
                      history.push("/otc");
                    }}
                  >
                    Transfer
                  </a>
                  {/* )
                  ) : null} */}
                </LinksOuter>
              </div>
              {/* <i className="fas fa-chevron-right"></i> */}
            </VuttinRight>
            {/* <RightIcon><i className="fas fa-angle-right"></i></RightIcon> */}
          </button>
          <BtnRight>
            <h4>Positions</h4>
            <div className="br-row">
              <span>
                <b className="green" />
                1st
              </span>
              <span>
                {Number(Web3.utils.fromWei(props.ele[1].balances[0])).toFixed(
                  4,
                )}
              </span>
              {props.ele[1].canRedeem ? (
                <Btn01
                  className="red"
                  onClick={() => {
                    // handleWithdrawTokens(
                    //   props.ele[1].token,
                    //   props.ele[1].balances[0],
                    //   props.ele[1].chainId,
                    // );
                  }}
                >
                  DELIST
                </Btn01>
              ) : (
                <Btn01
                  onClick={() => {
                    props.setDeactive();
                    props.setPopupOTCClaim();
                    // props.handleDepositTokens(props.ele[1]);
                  }}
                >
                  LIST OTC
                </Btn01>
              )}
            </div>
            <div className="br-row">
              <span>
                <b className="orange" />
                2nd
              </span>
              <span>
                {Number(Web3.utils.fromWei(props.ele[1].balances[1])).toFixed(
                  4,
                )}
              </span>
              {props.ele[1].canRedeem ? (
                <Btn01
                  className="red"
                  onClick={() => {
                    // handleWithdrawTokens(
                    //   props.ele[1].token,
                    //   props.ele[1].balances[1],
                    //   props.ele[1].chainId,
                    // );
                  }}
                >
                  DELIST
                </Btn01>
              ) : (
                <Btn01
                  onClick={() => {
                    props.setDeactive();
                    props.setPopupOTCClaim();
                    // props.handleDepositTokens(props.ele[1]);
                  }}
                >
                  LIST OTC
                </Btn01>
              )}
            </div>
            <div className="br-row">
              <span>
                <b className="red" />
                3rd
              </span>
              <span>
                {Number(Web3.utils.fromWei(props.ele[1].balances[2])).toFixed(
                  4,
                )}
              </span>
              {props.ele[1].canRedeem ? (
                <Btn01
                  className="red"
                  onClick={() => {
                    // handleWithdrawTokens(
                    //   props.ele[1].token,
                    //   props.ele[1].balances[2],
                    //   props.ele[1].chainId,
                    // );
                  }}
                >
                  DELIST
                </Btn01>
              ) : (
                <Btn01
                  onClick={() => {
                    props.setDeactive();
                    props.setPopupOTCClaim();
                    // props.handleDepositTokens(props.ele[1]);
                  }}
                >
                  LIST OTC
                </Btn01>
              )}
            </div>
          </BtnRight>
        </BtnRow>
      ) : null}
    </>
  );
}

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;
/* const RightIcon = styled.div`
  position: absolute; right: 14px; transform: translateY(-50%); top: 50%; color: #fff; font-size: 24px;
` */
const Mainheadbox = styled.div`
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  align-items: center;
  justify-content: space-between;
  min-height: 79px;
  background-color: ${(props) => props.theme.headbg01};
  z-index: 1000;
  border-bottom: 1px solid #1e2127;
  padding: 0 30px;
  ${Media.sm} {
    padding: 0px 10px 0px 20px;
  }
`;

const LogoBX = styled.div`
  a {
    display: inline-block;
  }
  img {
    max-width: 100%;
    height: auto;
    ${Media.sm} {
      width: 200px;
    }
  }
  ${Media.sm} {
    margin-right: 15px;
  }
`;

const HeadRightBX = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const SideBar = styled(FlexDiv)`
  width: 394px;
  position: fixed;
  top: 0;
  right: -100%;
  bottom: 0;
  background-color: #16191e;
  border-left: 1px solid #000;
  z-index: 10000;
  align-content: baseline;
  overflow-y: auto;
  transition: 0.6s ease-in-out;
  visibility: hidden;
  .closerBX {
    position: fixed;
    display: none;
    left: 0;
    top: 0;
    bottom: 0;
    width: calc(100% - 355px);
  }
  &.menuOpen {
    right: 0;
    visibility: visible;
    .closerBX {
      display: block;
    }
  }
  ${Media.sm} {
    width: 100%;
  }
`;

const WalletTxt = styled(FlexDiv)`
  justify-content: flex-start;
  font-size: 14px;
  letter-spacing: -0.25px;
  font-weight: 400;
  padding: 30px 25px;
  color: white;
  margin-bottom: 30px;
  ${Media.sm} {
    padding: 30px 40px 30px 25px;
  }
`;

const CloseBTN = styled.button`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 14px;
  top: 27px;
  padding: 0;
  margin: 0;
  transition: 0.4s linear;
  :hover {
    transform: rotate(180deg);
  }
`;
const TabLinkbx = styled(FlexDiv)`
  button {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    line-height: 40px;
    border-bottom: 2px solid #000000;
    width: 50%;
    &:hover,
    &.active {
      color: #00f02b;
      border-bottom: 2px solid #00f02b;
    }
  }
`;
const TabContainer = styled(FlexDiv)``;
const TabCBX01 = styled(FlexDiv)`
  justify-content: flex-start;
  button {
    width: 100%;
    min-height: 86px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 15px 29px;
    border-bottom: 1px solid #000000;
    :hover {
      background-color: #1d2229;
    }
    i.ImgBX {
      max-width: 46px;
      flex-shrink: 0;
      width: 100%;
      height: 46px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      margin-right: 14px;
      margin-top: 0;
    }
    .BTNText {
      margin: -4px 0 0 0;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      color: #fff;
      flex-direction: column;
      width: calc(100% - 13px);
      font-size: 18px;
      font-weight: 700;
      span {
        font-size: 12px;
        font-weight: 400;
        color: #9a9a9a;
        top: 0;
      }
      &.v2 {
        margin-left: auto;
        span {
          color: #fff;
          padding-top: 21px;
        }
      }
    }
    .fa-chevron-right {
      font-size: 20px;
      color: #fff;
      margin-left: auto;
    }
    &.v2 {
      flex-direction: column;
      .BTNText {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 0px 0px 5px;
        p {
          margin: 0px;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
`;

const MainLogBTN = styled.button`
  width: 32px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 0 6px;
  span:nth-child(01) {
    width: 8px;
    height: 8px;
    margin: 2px 0;
  }
  span:nth-child(02) {
    width: 6px;
    height: 6px;
    margin: 2px 0;
  }
  span:nth-child(03) {
    width: 4px;
    height: 4px;
    margin: 2px 0;
  }
`;

const Dot01 = styled.span`
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 6px;
  background-color: #00f02b;
  position: relative;
  padding: 0;
  margin: 0 0 0 40px;
  animation: pulse 2s infinite;
  border: none;
  outline: none;
  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(0, 240, 43, 0.4);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(0, 240, 43, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(0, 240, 43, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(0, 240, 43, 0.4);
      box-shadow: 0 0 0 0 rgba(0, 240, 43, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(0, 240, 43, 0);
      box-shadow: 0 0 0 10px rgba(0, 240, 43, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(0, 240, 43, 0);
      box-shadow: 0 0 0 0 rgba(0, 240, 43, 0);
    }
  }
`;

const VuttinRight = styled(FlexDiv)``;

const DFlex = styled(FlexDiv)`
  justify-content: space-between;
  margin: 0px 0px 5px;
  // span{margin-left:8px;}
  p {
    font-size: 12px;
    font-weight: 400;
    color: #9a9a9a;
    text-align: left;
    margin: 0;
    width: 43%;
    padding-right: 33px;
    margin-left: auto; /* word-wrap:break-word; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const DFlex2 = styled(FlexDiv)`
  justify-content: space-between;
  align-items: flex-start;
  font-size: 12px;
  font-weight: 400;
  color: #9a9a9a;
  margin: 0px 0px 3px;
  p {
    font-size: 11px;
    font-weight: 400;
    text-align: left;
    margin: 0;
    width: 43%;
    padding-right: 33px;
    margin-left: auto; /* word-wrap:break-word; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    &.v2 {
      width: auto;
      margin: 0;
    }
    &.text-blue {
      color: #2e2eff;
    }
    &:after {
      content: "";
      width: 20px;
      height: 1px;
      background: #000;
      right: 0;
      position: absolute;
      top: 50%;
      opacity: 0;
    }
    &:hover {
      color: #fff;
      text-decoration: underline;
      &:after {
        opacity: 1;
      }
    }
  }
  span {
    font-size: 11px !important;
    font-weight: 400;
  }
`;

const LinksOuter = styled(FlexDiv)`
  justify-content: flex-start;
  margin: 8px 0 0 0;
  flex-wrap: nowrap;
  white-space: nowrap;
  a {
    font-size: 11px;
    font-weight: 700;
    position: relative;
    margin-right: 20px;
    &.green {
      color: #00f02b;
    }
    &.red {
      color: #c32b2d;
    }
    &.white {
      color: #ffffff;
    }
    :after {
      content: "";
      width: 1px;
      height: 100%;
      background-color: #9a9a9a;
      position: absolute;
      right: -11px;
      top: 0;
    }
    :last-child {
      :after {
        display: none;
      }
    }
  }
`;
const BtnRight = styled(FlexDiv)`
  width: 50%;
  align-self: flex-start;
  text-align: left;
  justify-content: flex-start;
  padding: 0 28px;
  border-left: 1px solid #000;
  margin: 20px 0 0 0;
  h4 {
    margin: 0 0 10px;
    color: #9a9a9a;
    font-size: 12px;
  }
  .br-row {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #000;
    padding: 4px 0;
    align-items: center;
    span {
      font-size: 12px;
      color: #9a9a9a;
      width: 33.33%;
      b {
        width: 7px;
        height: 7px;
        border-radius: 100%;
        margin-right: 8px;
        display: inline-block;
        &.green {
          background: #00f02b;
          box-shadow: 0 0 10px #00f02b;
        }
        &.orange {
          background: #e4a029;
          box-shadow: 0 0 10px #e4a029;
        }
        &.red {
          background: #c32b2d;
          box-shadow: 0 0 10px #c32b2d;
        }
      }
    }
  }
`;
const Btn01 = styled.a`
  font-size: 11px;
  color: #00f02b;
  border: 2px solid transparent;
  height: 20px;
  width: 90px;
  text-align: center;
  display: inline-block;
  padding: 2px 0 0 0;
  border-radius: 2px;
  margin-left: auto;
  cursor: pointer;

  &:hover {
    border: 2px solid #00f02b;
    box-shadow: 0 0 6px #00f02bab;
  }
  &.red {
    color: #c32b2d;
    &:hover {
      border: 2px solid #c32b2d;
      box-shadow: 0 0 6px #c32b2dab;
    }
  }
`;
const BtnRow = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #000;
`;

export default TokenDetails;
