/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { useEffect, useState, useReducer, useContext } from "react";
import Collapse from "@kunukn/react-collapse";
import styled from "styled-components";
import Media from "../Theme/media-breackpoint";
import Icon1 from "../Assets/images/swap.png";
import Icon2 from "../Assets/images/push.png";
import new_token from "./../Assets/images/new_token.png";
import CoinGecko from "./../Assets/images/CoinGecko.png";
import ReactLoaderSpinner from "react-loader-spinner";
import { ACTIVE_CHAINS, explorerLink, nativeTokenList } from "../constants";
import { otcCancelOrder } from "../Service/otc_function";
import notificationConfig from "../config/notificationConfig";
import Web3 from "web3";
import { toUpper } from "lodash";
import ReactTooltip from "react-tooltip";
import PriceSupplyLineChart from "./charts/priceSupplyLineChart";
import AskBidBarChart from "./charts/askBidBarChart";
import { changeNetwork } from "../Service/metamask_function";
import { GlobalContext } from "../Context/globalContext";
import { formatPrice } from "../utils/utils";

function OtcExchange(props) {
  const [ToggleState, setToggleState] = useState(1);
  const [chainSelected, setChainSelected] = useState("56");
  const [defaultPair, setDefaultPair] = useState("DEGEN/BNB");
  const [showFilter, setShowFilter] = useState(false);
  const [account, setAccount] = useState(null);
  const [pairs, setPairs] = useState(null);
  const [currencyPrices, setCurrencyPrices] = useState({});
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [pairsLoading, setPairsLoading] = useState(false);
  const [otcOrders, setOtcOrders] = useState([]);
  const [historyPurchases, setHistoryPurchases] = useState([]);
  const [historySelling, setHistorySelling] = useState([]);
  const [offersByMe, setOffersByMe] = useState([]);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [otcTradersData, setOtcTradersData] = useState([]);
  const [showBid, setShowBid] = useState(false);
  const [sales, setSales] = useState(false);
  const [globalChainId, setGlogalChainId] = useState(null);
  const [tokenList, setTokenList] = useState(null);
  const [all, setAll] = useState(true);
  const [searchToken, setSearchToken] = useState(null);
  const [listView, setListView] = useState(true);
  const [dropDown, setDropDown] = useState(false);
  const [position, setPosition] = useState(null);
  const globalContext = useContext(GlobalContext);
  const [filterInput, setFilterInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      tokenSymbol: "",
      askingToken: "",
      minSupplyAvailable: "",
      maxSupplyAvailable: "",
      minAskingAmount: "",
      maxAskingAmount: "",
      minAskingPrice: "",
      maxAskingPrice: "",
      liquidityPositionsArr: [],
      minDiscount: "",
      maxDiscount: "",
    }
  );
  // another content..
  // console.log(filterInput.liquidityPositionsArr);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    setGlogalChainId(props.chainId);
    // console.log(props.historyPurchases)
  }, [props.chainId]);

  useEffect(() => {
    setOtcOrders(props.otcOrders);
  }, [props.otcOrders]);

  useEffect(() => {
    setOffersByMe(props.offersByMe);
  }, [props.offersByMe]);

  useEffect(() => {
    setHistoryPurchases(props.historyPurchases);
    // console.log(props.historyPurchases)
  }, [props.historyPurchases]);

  useEffect(() => {
    setHistorySelling(props.historySelling);
  }, [props.historySelling]);

  useEffect(() => {
    setOrdersLoading(props.ordersLoading);
    setPairsLoading(props.ordersLoading);
  }, [props.ordersLoading]);

  useEffect(() => {
    setCurrencyPrices(props.currencyPrices);
  }, [props.currencyPrices]);

  useEffect(() => {
    setPairs(props.pairs);
  }, [props.pairs]);

  useEffect(() => {
    setAccount(props.account);
  }, [props.account]);

  useEffect(() => {
    setOtcTradersData(props.otcTradersData);
  }, [props.otcTradersData]);

  useEffect(() => {
    if (globalContext.tokenList !== null) {
      let list = [];
      Object.entries(globalContext.tokenList).map((pairWiseList, key) => {
        return Object.entries(pairWiseList[1]).map((ele, keyin) => {
          if (ele[1].dumperShieldToken) list.push(ele[1]);
        });
      });
      setTokenList(list);
    }
  }, [globalContext.tokenList]);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [isOpen, setOpen] = React.useState(true);

  const getActiveClass = (index, className) =>
    ToggleState === index ? className : "";

  function splitString(str) {
    const delimiter = /(\$| to | )/; // Matches '$' or ' to '
    const parts = str
      .split(delimiter)
      .filter(
        (part) => part !== "$" && part !== " to " && part !== "" && part !== " "
      ); // Split the string on the delimiter and filter out delimiters

    return parts;
  }

  function getVal(val) {
    if (isNaN(val)) {
      let multiplier = val.substr(-1).toLowerCase();
      if (multiplier == "k") return parseFloat(val) * 1000;
      else if (multiplier == "m") return parseFloat(val) * 1000000;
    } else {
      return Number(val);
    }
  }

  const handleCancelOrder = async (order) => {
    if (Number(order.chainId) !== Number(Web3.utils.toDecimal(globalChainId))) {
      await changeNetwork(Web3.utils.toHex(order.chainId));
      return 0;
    }
    setCancelLoading(true);

    let otcCancelOrderData = {};

    otcCancelOrderData["orderId"] = order.orderId;

    await otcCancelOrder(
      otcCancelOrderData,
      (hash) => {},
      async (receipt) => {
        setCancelLoading(false);
        notificationConfig.success("Order cancelled Successfully!");
      },
      async (error) => {
        setCancelLoading(false);
        if (error.code === 4001) {
          notificationConfig.error("Transaction rejected!");
        }
      }
    );
  };

  const handlePairClick = (e) => {
    const { innerText } = e.target;
    const name = e.target.attributes.name.nodeValue;
    setFilterInput({ [name]: innerText });
  };

  const handleFilters = (event) => {
    const { name, value } = event.target;
    if (name === "1st" || name === "2nd" || name === "3rd") {
      if (event.target.checked) {
        setFilterInput({
          liquidityPositionsArr: [...filterInput.liquidityPositionsArr, name],
        });
      } else {
        const tempArr = filterInput.liquidityPositionsArr.filter(
          (data) => data !== name
        );
        setFilterInput({ liquidityPositionsArr: tempArr });
      }
    } else {
      setFilterInput({ [name]: value });
    }
  };

  const handleSearchToken = (event) => {
    setSearchToken(event.target.value);
  };

  const setValuesInFilter = (name, minValue, maxValue) => {
    if (name === "supplyAvailable") {
      setFilterInput({
        minSupplyAvailable: minValue,
        maxSupplyAvailable: maxValue,
      });
    } else if (name === "askingAmount") {
      setFilterInput({
        minAskingAmount: minValue,
        maxAskingAmount: maxValue,
      });
    } else {
      setFilterInput({
        minAskingPrice: minValue,
        maxAskingPrice: maxValue,
      });
    }
  };

  const handleNumericalValueSelectFilters = (e) => {
    const { innerText } = e.target;
    const name = e.target.attributes.name.nodeValue;
    const values = splitString(innerText);

    if (values[0].includes("Above")) {
      const minValue = getVal(values[1]);
      const maxValue = Infinity;
      setValuesInFilter(name, minValue, maxValue);
    } else {
      const minValue = getVal(values[0]);
      const maxValue = getVal(values[1]);
      setValuesInFilter(name, minValue, maxValue);
    }
  };

  const handleTransferTokens = (e) => {};

  const filterOtcOrders = (orders) => {
    // console.log(filterInput.tokenSymbol);
    if (orders[chainSelected]) {
      let arr = orders[chainSelected].filter((obj) => {
        return (
          obj.pair.split("/")[0].includes(toUpper(filterInput.tokenSymbol)) &&
          obj.pair.split("/")[1].includes(toUpper(filterInput.askingToken)) &&
          (filterInput.minSupplyAvailable
            ? obj.supplyAvailable >= filterInput.minSupplyAvailable
            : true) &&
          (filterInput.maxSupplyAvailable
            ? obj.supplyAvailable <= filterInput.maxSupplyAvailable
            : true) &&
          (filterInput.minAskingPrice
            ? obj.askingAmountUSD >= filterInput.minAskingPrice
            : true) &&
          (filterInput.maxAskingPrice
            ? obj.askingAmountUSD <= filterInput.maxAskingPrice
            : true) &&
          (filterInput.minAskingAmount
            ? Number(obj.askingAmount.split(" ")[0]) >=
              filterInput.minAskingAmount
            : true) &&
          (filterInput.maxAskingAmount
            ? Number(obj.askingAmount.split(" ")[0]) <=
              filterInput.maxAskingAmount
            : true) &&
          (filterInput.liquidityPositionsArr.length
            ? filterInput.liquidityPositionsArr.includes(obj.liquidityPosition)
            : true) &&
          (filterInput.minDiscount
            ? obj.competitiveValuePercentage >= filterInput.minDiscount
            : true) &&
          (filterInput.maxDiscount
            ? obj.competitiveValuePercentage <= filterInput.maxDiscount
            : true)
        );
      });
      // console.log(arr);
      return arr;
    }
  };

  const filteredOtcOrders = filterOtcOrders(otcOrders);

  const onToggle = (dropDownNew) =>
    setDropDown(dropDown === dropDownNew ? null : dropDownNew);

  return (
    <>
      {/* <Filter>
        <Tabs className='v2'>
            <a ><i class="fas fa-exclamation-circle"></i> How it works</a><span>|</span>
            <a>Asks vs bids</a>
        </Tabs>
        
        <a
          className='filter-icon'
          style={{ color: 'white' }}
          href='javascript:void(0)'
          onClick={() => setShowFilter(showFilter === true ? false : false)}
        >
          <i className='fas fa-filter'></i>
        </a>
        { !ordersLoading ?
        <button
          className='btn01'
          onClick={() => {
            props.setPopupOTCClaim(true);
          }}
        >
          LIST OTC
        </button> : null
        }
      </Filter> */}
      {showFilter ? (
        <FilterMain>
          <FilterBox>
            <h4>Token Symbol</h4>
            <div className="searchBox">
              <i className="fas fa-search"></i>
              <input
                className="search"
                type="text"
                placeholder="Search"
                name="tokenSymbol"
                value={filterInput.tokenSymbol}
                onChange={handleFilters}
              />
            </div>
            <ul>
              <li>
                <SpanButton onClick={handlePairClick} name="tokenSymbol">
                  JNTR
                </SpanButton>
              </li>
              <li>
                <SpanButton onClick={handlePairClick} name="tokenSymbol">
                  SMART
                </SpanButton>
              </li>
              <li>
                <SpanButton onClick={handlePairClick} name="tokenSymbol">
                  PDO
                </SpanButton>
              </li>
              <li>
                <SpanButton onClick={handlePairClick} name="tokenSymbol">
                  FREEZ
                </SpanButton>
              </li>
              <li>
                <SpanButton onClick={handlePairClick} name="tokenSymbol">
                  DEGEN
                </SpanButton>
              </li>
            </ul>
          </FilterBox>
          <FilterBox>
            <h4>Asking token</h4>
            <div className="searchBox">
              <i className="fas fa-search"></i>
              <input
                className="search"
                type="text"
                placeholder="Search"
                name="askingToken"
                value={filterInput.askingToken}
                onChange={handleFilters}
              />
            </div>
            <ul>
              <li>
                <SpanButton name="askingToken" onClick={handlePairClick}>
                  ETH
                </SpanButton>
              </li>
              <li>
                <SpanButton name="askingToken" onClick={handlePairClick}>
                  BNB
                </SpanButton>
              </li>
            </ul>
          </FilterBox>
          <FilterBox>
            <h4>Supply Available</h4>
            <MaxMin>
              <input
                className="textBox"
                type="number"
                placeholder="Min"
                name="minSupplyAvailable"
                value={filterInput.minSupplyAvailable}
                onChange={handleFilters}
              ></input>
              <span>To</span>
              <input
                className="textBox"
                type="number"
                placeholder="Max"
                name="maxSupplyAvailable"
                value={filterInput.maxSupplyAvailable}
                onChange={handleFilters}
              ></input>
            </MaxMin>
            <ul>
              <li>
                <SpanButton
                  name="supplyAvailable"
                  onClick={handleNumericalValueSelectFilters}
                >
                  0 to 100
                </SpanButton>
              </li>
              <li>
                <SpanButton
                  name="supplyAvailable"
                  onClick={handleNumericalValueSelectFilters}
                >
                  100 to 1k
                </SpanButton>
              </li>
              <li>
                <SpanButton
                  name="supplyAvailable"
                  onClick={handleNumericalValueSelectFilters}
                >
                  1k to 10k
                </SpanButton>
              </li>
              <li>
                <SpanButton
                  name="supplyAvailable"
                  onClick={handleNumericalValueSelectFilters}
                >
                  10k to 100k
                </SpanButton>
              </li>
              <li>
                <SpanButton
                  name="supplyAvailable"
                  onClick={handleNumericalValueSelectFilters}
                >
                  Above 100k
                </SpanButton>
              </li>
            </ul>
          </FilterBox>
          <FilterBox>
            <h4>Asking Amount</h4>
            <MaxMin>
              <input
                className="textBox"
                type="number"
                placeholder="Min"
                name="minAskingAmount"
                value={filterInput.minAskingAmount}
                onChange={handleFilters}
              ></input>
              <span>To</span>
              <input
                className="textBox"
                type="number"
                placeholder="Max"
                name="maxAskingAmount"
                value={filterInput.maxAskingAmount}
                onChange={handleFilters}
              ></input>
            </MaxMin>
            <ul>
              <li>
                <SpanButton
                  name="askingAmount"
                  onClick={handleNumericalValueSelectFilters}
                >
                  0 to 100
                </SpanButton>
              </li>
              <li>
                <SpanButton
                  name="askingAmount"
                  onClick={handleNumericalValueSelectFilters}
                >
                  100 to 1k
                </SpanButton>
              </li>
              <li>
                <SpanButton
                  name="askingAmount"
                  onClick={handleNumericalValueSelectFilters}
                >
                  1k to 10k
                </SpanButton>
              </li>
              <li>
                <SpanButton
                  name="askingAmount"
                  onClick={handleNumericalValueSelectFilters}
                >
                  10k to 100k
                </SpanButton>
              </li>
              <li>
                <SpanButton
                  name="askingAmount"
                  onClick={handleNumericalValueSelectFilters}
                >
                  Above 100k
                </SpanButton>
              </li>
            </ul>
          </FilterBox>
          <FilterBox>
            <h4>Asking Price</h4>
            <MaxMin>
              <input
                className="textBox"
                type="number"
                placeholder="Min"
                name="minAskingPrice"
                onChange={handleFilters}
                value={filterInput.minAskingPrice}
              ></input>
              <span>To</span>
              <input
                className="textBox"
                type="number"
                placeholder="Max"
                name="maxAskingPrice"
                value={filterInput.maxAskingPrice}
                onChange={handleFilters}
              ></input>
            </MaxMin>
            <ul>
              <li>
                <SpanButton
                  name="askingPrice"
                  onClick={handleNumericalValueSelectFilters}
                >
                  $0 to $100
                </SpanButton>
              </li>
              <li>
                <SpanButton
                  name="askingPrice"
                  onClick={handleNumericalValueSelectFilters}
                >
                  $100 to $1k
                </SpanButton>
              </li>
              <li>
                <SpanButton
                  name="askingPrice"
                  onClick={handleNumericalValueSelectFilters}
                >
                  $1k to $10k
                </SpanButton>
              </li>
              <li>
                <SpanButton
                  name="askingPrice"
                  onClick={handleNumericalValueSelectFilters}
                >
                  $10k to $100k
                </SpanButton>
              </li>
              <li>
                <SpanButton
                  name="askingPrice"
                  onClick={handleNumericalValueSelectFilters}
                >
                  Above $100k
                </SpanButton>
              </li>
            </ul>
          </FilterBox>
          <FilterBox>
            <h4>Liquidity positions </h4>
            <ul className="checkList">
              <li>
                <div className="md-checkbox md-checkbox-inline">
                  <input
                    type="checkbox"
                    id="checkbox01"
                    name="1st"
                    onClick={handleFilters}
                  />
                  <label htmlFor="checkbox01">1st position</label>
                </div>
              </li>
              <li>
                <div className="md-checkbox md-checkbox-inline">
                  <input
                    type="checkbox"
                    id="checkbox02"
                    name="2nd"
                    onClick={handleFilters}
                  />
                  <label htmlFor="checkbox02">2nd position</label>
                </div>
              </li>
              <li>
                <div className="md-checkbox md-checkbox-inline">
                  <input
                    type="checkbox"
                    id="checkbox03"
                    name="3rd"
                    onClick={handleFilters}
                  />
                  <label htmlFor="checkbox03">3rd position</label>
                </div>{" "}
              </li>
            </ul>
          </FilterBox>
          <div className="hasFilter">
            <FilterBox className="discount">
              <h4>Vesting Due Date</h4>
              <Discount>
                <div className="hasBox">
                  <input
                    className="textBox"
                    placeholder="Max"
                    type="number"
                    value={filterInput.minDiscount}
                    onChange={handleFilters}
                    name="minDiscount"
                  />
                  <strong className="xl">DAYS</strong>
                </div>
              </Discount>
            </FilterBox>
            <FilterBox className="discount">
              <h4>Discount</h4>
              <Discount>
                <div className="hasBox">
                  <input
                    className="textBox"
                    placeholder="Min"
                    type="number"
                    value={filterInput.minDiscount}
                    onChange={handleFilters}
                    name="minDiscount"
                  />
                  <strong>%</strong>
                </div>
                <span>To</span>
                <div className="hasBox">
                  <input
                    className="textBox"
                    placeholder="Max"
                    type="number"
                    onChange={handleFilters}
                    name="maxDiscount"
                    value={filterInput.maxDiscount}
                  />
                  <strong>%</strong>
                </div>
              </Discount>
            </FilterBox>
          </div>
        </FilterMain>
      ) : null}

      {/* <TabBox>
            <a className='close'><i class="fas fa-times"></i></a>
            <h5>How it works</h5>
            <p>The decentralized OTC (over-the-counter) system allows users to list and transfer tokens to other users anonymously, without any pre-engagement and without negatively impacting the project's market price.</p>
            <p>Users can list tokens for sale or place bids to buy, indicating the desired amount and price. The ask or bid price can be fixed or adjustable based on the token's market value. Counterparties have the option to accept offers fully or partially, and any remaining amount will be automatically listed for future buyers without the seller or buyer needing to take any action. Payments are processed directly to the seller's wallet, outside of the Dumper Shield.</p>
        </TabBox>
        <TabBox>
            <a className='close'><i class="fas fa-times"></i></a>
            <h5>How it works</h5>
            <p>The Dumper Shield is a feature that enables sellers to place ask orders and buyers to place bid orders, creating a marketplace for the buying and selling of tokens. Sellers can specify the amount of tokens they wish to sell and the price they are asking for, while buyers can indicate the amount they are willing to buy and the price they are willing to pay. This allows for a more efficient and transparent way of trading, as buyers and sellers can directly interact with each other without the need for intermediaries or any personal engagement over social media chat.</p>
        </TabBox> */}
      <Tabs style={{ marginTop: "40px" }}>
        <button
          className={sales ? "active" : ""}
          onClick={() => setSales(true)}
        >
          Sales
        </button>
        <span>|</span>
        <button
          className={!sales ? "active" : ""}
          onClick={() => setSales(false)}
        >
          Depth
        </button>
        <span>|</span>
        <button>Order Book</button>
        <span>|</span>
        <button>Token Release Schedule</button>
        <ProgressMain>
          <Progress>48,5%</Progress>
          51,5%
        </ProgressMain>
        <i className="fas fa-caret-up"></i>
      </Tabs>
      <Tabs style={{ justifyContent: "space-between" }}>
        <p className="text-gray-400">
          Total allocation{" "}
          <span className="text-white">
            {pairs !== null
              ? pairs[chainSelected]
                ? pairs[chainSelected][defaultPair]
                  ? Number(
                      pairs[chainSelected][defaultPair].totalAllocation * 2
                    ).toLocaleString("en-US")
                  : null
                : null
              : null}
          </span>
        </p>
        <CheckBoxes>
          <div className="md-checkbox md-checkbox-inline">
            <input type="checkbox" id="checkbox01" name="" />
            <label htmlFor="checkbox01">Market price</label>
          </div>
          <div className="md-checkbox md-checkbox-inline">
            <input type="checkbox" id="checkbox02" name="" />
            <label htmlFor="checkbox02">Highest bids</label>
          </div>
          <div className="md-checkbox md-checkbox-inline">
            <input type="checkbox" id="checkbox03" name="" />
            <label htmlFor="checkbox03">Average bids</label>
          </div>
          <div className="md-checkbox md-checkbox-inline">
            <input type="checkbox" id="checkbox04" name="" />
            <label htmlFor="checkbox04">Lowest bids</label>
          </div>
        </CheckBoxes>
      </Tabs>
      <ChartContainer>
        {sales ? (
          <PriceSupplyLineChart
            priceData={
              pairs !== null
                ? pairs[chainSelected]
                  ? pairs[chainSelected][defaultPair]
                    ? pairs[chainSelected][defaultPair].pricesChart
                    : []
                  : []
                : []
            }
            supply={
              pairs !== null
                ? pairs[chainSelected]
                  ? pairs[chainSelected][defaultPair]
                    ? pairs[chainSelected][defaultPair].supplyChart
                    : []
                  : []
                : []
            }
            date={
              pairs !== null
                ? pairs[chainSelected]
                  ? pairs[chainSelected][defaultPair]
                    ? pairs[chainSelected][defaultPair].date
                    : []
                  : []
                : []
            }
            marketPrices={
              pairs !== null
                ? pairs[chainSelected]
                  ? pairs[chainSelected][defaultPair]
                    ? pairs[chainSelected][defaultPair].marketPricesChart
                    : []
                  : []
                : []
            }
          />
        ) : (
          <AskBidBarChart
            priceData={
              pairs !== null
                ? pairs[chainSelected]
                  ? pairs[chainSelected][defaultPair]
                    ? pairs[chainSelected][defaultPair].pricesChart
                    : []
                  : []
                : []
            }
            supply={
              pairs !== null
                ? pairs[chainSelected]
                  ? pairs[chainSelected][defaultPair]
                    ? pairs[chainSelected][defaultPair].supplyChart
                    : []
                  : []
                : []
            }
            date={
              pairs !== null
                ? pairs[chainSelected]
                  ? pairs[chainSelected][defaultPair]
                    ? pairs[chainSelected][defaultPair].date
                    : []
                  : []
                : []
            }
            marketPrices={
              pairs !== null
                ? pairs[chainSelected]
                  ? pairs[chainSelected][defaultPair]
                    ? pairs[chainSelected][defaultPair].marketPricesChart
                    : []
                  : []
                : []
            }
          />
        )}
      </ChartContainer>
      <div style={{ display: "flex", position: "relative" }}>
        <ButtonGroup style={{ marginRight: "5px" }}>
          <button
            className={`${!showBid ? "buy" : ""} btn`}
            style={{ borderRight: "1px solid #1e2127" }}
            onClick={() => {
              if (showBid === true) {
                setDefaultPair(
                  defaultPair.split("/")[1] + "/" + defaultPair.split("/")[0]
                );
                setShowBid(false);
              }
            }}
          >
            Buy
          </button>
          <button
            className={`${showBid ? "sell" : ""} btn`}
            onClick={() => {
              if (showBid === false) {
                setDefaultPair(
                  defaultPair.split("/")[1] + "/" + defaultPair.split("/")[0]
                );
                setShowBid(true);
              }
            }}
          >
            Sell
          </button>
        </ButtonGroup>
        <ButtonGroup style={{ marginRight: "5px", marginLeft: "5px" }}>
          <button
            className={`${all ? "active" : ""} btn`}
            style={{ borderRight: "1px solid #1e2127" }}
            onClick={() => setAll(true)}
          >
            All
          </button>
          <button
            className={`${!all ? "active" : ""} btn`}
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => setAll(false)}
          >
            <img src={CoinGecko} style={{ marginRight: "10px" }} />
            <span style={{ marginRight: "20px" }}>Verified</span>
          </button>
        </ButtonGroup>
        <DDMenu onClick={() => onToggle(1)}>
          {position !== null ? position : "Positions"}
          <div className="DDContainer">
            <Collapse
              className={"collapse " + (dropDown === 1 ? "active" : "")}
              isOpen={dropDown === 1}
              onChange={({ state }) => {}}
              onInit={({ state }) => {}}
            >
              <div className="DDSubContainer">
                <button
                  onClick={() => {
                    setPosition("1st");
                  }}
                >
                  1st
                </button>
                <button
                  onClick={() => {
                    setPosition("2nd");
                  }}
                >
                  2nd
                </button>
                <button
                  onClick={() => {
                    setPosition("3rd");
                  }}
                >
                  3rd
                </button>
              </div>
            </Collapse>
          </div>
        </DDMenu>
        <SeachInput style={{ marginRight: "5px", marginLeft: "5px" }}>
          <i className="fas fa-search" style={{ marginRight: "10px" }}></i>
          <input
            style={{ color: "white" }}
            className="textBox"
            placeholder="Search token"
            type="text"
            onChange={handleSearchToken}
            name="seachToken"
            value={searchToken}
          />
        </SeachInput>
        <a
          style={{
            alignItems: "center",
            color: "white",
            border: "1px solid #1e2127",
            display: "flex",
            padding: "0px 10px",
            marginRight: "5px",
            marginLeft: "5px",
          }}
          href="javascript:void(0)"
          onClick={() => setShowFilter(showFilter === true ? false : false)}
        >
          <i className="fas fa-filter"></i>
        </a>
        {!ordersLoading ? (
          <FilterButton
            style={{ marginLeft: "5px" }}
            onClick={() => {
              props.setPopupOTCClaim(true);
            }}
          >
            LIST OTC
          </FilterButton>
        ) : null}
      </div>
      <Tabs className="v2" style={{ marginTop: "30px" }}>
        <a>
          <i className="fas fa-exclamation-circle"></i> How it works
        </a>
        <span>|</span>
        <a>Asks vs bids</a>
        <span>|</span>
        <a>Positions</a>
        <span>|</span>
        <a>AI Gateway</a>
      </Tabs>
      <ExchangeMain>
        <div className={`leftCol ${!isOpen ? "hidden" : ""}`}>
          <TableTitle>
            <h3>Chains</h3>
          </TableTitle>
          <div className="ChainsList">
            {ACTIVE_CHAINS.map((ele) => {
              let chainId = Web3.utils.toDecimal(ele);
              return (
                <a
                  className={chainSelected === chainId ? "active" : ""}
                  onClick={() => {
                    setChainSelected(chainId);
                    setShowBid(false);
                    setAll(true);
                    setPosition(null);
                    setDefaultPair(
                      pairs[chainId] ? Object.entries(pairs[chainId])[0][0] : ""
                    );
                  }}
                >
                  <img src={nativeTokenList[chainId].logoURI} alt="chain"></img>
                  <span>{nativeTokenList[chainId].networkName}</span>
                </a>
              );
            })}
          </div>
          <TableBottom>
            <h5>
              Chains <span className="color-green">{ACTIVE_CHAINS.length}</span>
            </h5>
          </TableBottom>
        </div>
        <div className={`centerCol ${!isOpen ? "hidden" : ""}`}>
          {/* =========================================================Pairs=Table=========================================================== */}
          <TableTitle>
            <h3>My Pairs</h3>
            <div className="rightTitle v2">
              <img
                onClick={() => setOpen(!isOpen)}
                className="actionIcon "
                src={Icon2}
              ></img>
            </div>
          </TableTitle>
          <TableMain className="height3">
            <table width="100%" border="0" cellSpacing="0" cellPadding="20">
              <thead>
                <tr>
                  <th align="left" valign="middle" scope="col">
                    Pair{" "}
                    <a href="#" className="sortLink">
                      <i className="fas fa-sort"></i>
                    </a>
                  </th>
                  <th align="left" valign="middle" scope="col">
                    Avg ask{" "}
                    <a href="#" className="sortLink">
                      <i className="fas fa-sort"></i>
                    </a>
                  </th>
                  <th align="left" valign="middle" scope="col">
                    Change{" "}
                    <a href="#" className="sortLink">
                      <i className="fas fa-sort"></i>
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody>
                {pairsLoading ? (
                  <tr>
                    <td colSpan="3" align="middle">
                      <ReactLoaderSpinner
                        type="Bars"
                        color="#fff"
                        height={45}
                        width={130}
                      />
                    </td>
                  </tr>
                ) : pairs !== null && otcTradersData[chainSelected] ? (
                  otcTradersData[chainSelected]
                    .filter(
                      (ele) =>
                        all === true ||
                        (all === false &&
                          tokenList.find(
                            (e) =>
                              e.symbol
                                .toLowerCase()
                                .includes(ele.pairNames.split("/")[0]) ||
                              e.symbol
                                .toLowerCase()
                                .includes(ele.pairNames.split("/")[1])
                          ) !== null)
                    )
                    .map((ele, key) => {
                      return (
                        <tr key={key}>
                          <td
                            className="clickable"
                            onClick={() => {
                              setDefaultPair(ele.pairNames);
                              setShowBid(
                                pairs[chainSelected][ele.pairNames][0]
                                  .orderType === "ask"
                                  ? false
                                  : true
                              );
                            }}
                          >
                            <Nowrap>
                              <img
                                // className='actionIcon push'
                                src={
                                  ele.tokenLogo !== ""
                                    ? ele.tokenLogo
                                    : new_token
                                }
                              ></img>{" "}
                              {ele.pairNames}
                            </Nowrap>
                          </td>
                          <td
                            data-tip={
                              Number(ele.median) +
                              nativeTokenList[chainSelected].symbol
                            }
                          >
                            {formatPrice(ele.median)}{" "}
                            {nativeTokenList[chainSelected].symbol + " "}
                            <Block className="color-dull">
                              [${formatPrice(ele.medianPriceAllUSD)}]
                            </Block>
                          </td>
                          <td
                            data-tip={""}
                            className={
                              ele.change > 0
                                ? "color-green"
                                : ele.change === 0
                                ? ""
                                : "color-red"
                            }
                          >
                            {ele.change > 0 ? (
                              <i className="fas fa-caret-up"></i>
                            ) : ele.change === 0 ? (
                              ""
                            ) : (
                              <i className="fas fa-caret-down"></i>
                            )}{" "}
                            {formatPrice(ele.change)} %
                          </td>
                        </tr>
                      );
                    })
                ) : (
                  <tr>
                    <td colSpan="3" align="middle">
                      No Records
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </TableMain>
        </div>
        <div className={`rightCol ${!isOpen ? "expand" : ""}`}>
          {/* =========================================================OTC=ASK=BID=Table=========================================================== */}
          <TableTitle>
            <img
              onClick={() => setOpen(!isOpen)}
              className="actionIcon push"
              src={Icon2}
            ></img>
            <h3>
              <span className={`${!showBid ? "bold-white" : "color-dull"}`}>
                Buy
              </span>
              <img
                className="actionIcon"
                src={Icon1}
                style={{ width: "30px", margin: "0px 10px" }}
              ></img>
              <span className={`${showBid ? "bold-white" : "color-dull"} `}>
                Sell
              </span>
              <span className="color-dull"> | </span>
              <span>{defaultPair.split("/")[0]}</span>
              <b className="color-dull">
                {"/"}
                {defaultPair.split("/")[1]}
              </b>
            </h3>
            <span className="color-dull">
              &nbsp;| Avg Ask{" "}
              <b className="bold-white">
                {pairs !== null
                  ? Number(
                      otcTradersData
                        ? otcTradersData[chainSelected]
                          ? otcTradersData[chainSelected].find(
                              (el) => el.pairNames === defaultPair
                            )
                            ? Number(
                                otcTradersData[chainSelected].find(
                                  (el) => el.pairNames === defaultPair
                                ).median
                              ).toFixed(4)
                            : 0
                          : 0
                        : 0
                    ).toFixed(4)
                  : "0.0000"}
              </b>{" "}
              ($
              {Number(
                otcTradersData
                  ? otcTradersData[chainSelected]
                    ? otcTradersData[chainSelected].find(
                        (el) => el.pairNames === defaultPair
                      )
                      ? Number(
                          otcTradersData[chainSelected].find(
                            (el) => el.pairNames === defaultPair
                          ).medianPriceAllUSD
                        ).toFixed(2)
                      : 0
                    : 0
                  : 0
              ).toFixed(2)}
              ) | Lower ask{" "}
              <b className="bold-white">
                {pairs !== null
                  ? Number(
                      otcTradersData
                        ? otcTradersData[chainSelected]
                          ? otcTradersData[chainSelected].find(
                              (el) => el.pairNames === defaultPair
                            )
                            ? Number(
                                otcTradersData[chainSelected].find(
                                  (el) => el.pairNames === defaultPair
                                ).lowestAsk
                              ).toFixed(6)
                            : 0
                          : 0
                        : 0
                    ).toFixed(6)
                  : "0.00"}
              </b>
              {defaultPair && props.dsUsers !== null && pairs ? (
                <>
                  <span> | Unique sellers </span>
                  <b className="bold-white">
                    {otcTradersData
                      ? otcTradersData[chainSelected]
                        ? otcTradersData[chainSelected].find(
                            (el) => el.pairNames === defaultPair
                          )
                          ? otcTradersData[chainSelected].find(
                              (el) => el.pairNames === defaultPair
                            ).uniqueSellers
                          : null
                        : null
                      : null}
                  </b>
                </>
              ) : null}
            </span>
            <div
              className="rightTitle"
              style={{ display: "flex", alignItems: "center" }}
            >
              <i
                className={`fas fa-th-list ${
                  listView ? "color-white" : "color-dull"
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => setListView(true)}
              ></i>
              <span className="color-dull" style={{ margin: "0 5px" }}>
                {" "}
                |{" "}
              </span>
              <i
                className={`fas fa-stop ${
                  !listView ? "color-white" : "color-dull"
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => setListView(false)}
              ></i>
            </div>
          </TableTitle>
          <TableMain className="height3">
            {listView ? (
              <table
                className="border-cell"
                width="100%"
                border="0"
                cellSpacing="0"
                cellPadding="20"
              >
                {showBid ? (
                  <thead>
                    <tr>
                      <th align="left" valign="middle" scope="col">
                        Token Pairs{" "}
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Vesting Due Date{" "}
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Liquidity Position{" "}
                        <span
                          data-html="true"
                          data-tip="test"
                          className="helpIco"
                          currentitem="false"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>{" "}
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>

                      <th align="left" valign="middle" scope="col">
                        Asking Allocation
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Market Price{" "}
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>
                      <th
                        align="left"
                        valign="middle"
                        scope="col"
                        className="highlighted"
                      >
                        Bid Price
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Price Difference{" "}
                        <span
                          data-html="true"
                          data-tip="test"
                          className="helpIco"
                          currentitem="false"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>{" "}
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>
                      <th
                        className="ColSticky"
                        align="center"
                        valign="middle"
                        scope="col"
                      >
                        Accept Offer
                      </th>
                    </tr>
                  </thead>
                ) : (
                  <thead>
                    <tr>
                      <th align="left" valign="middle" scope="col">
                        Token Pairs{" "}
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Vesting Due Date{" "}
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Liquidity Position{" "}
                        <span
                          data-html="true"
                          data-tip="test"
                          className="helpIco"
                          currentitem="false"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>{" "}
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Offering Allocation
                        <span
                          data-html="true"
                          data-tip="test"
                          className="helpIco"
                          currentitem="false"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Market Price{" "}
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>
                      <th
                        align="left"
                        valign="middle"
                        scope="col"
                        className="highlighted"
                      >
                        Asking Price
                        <span
                          data-html="true"
                          data-tip="test"
                          className="helpIco"
                          currentitem="false"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Price Difference{" "}
                        <span
                          data-html="true"
                          data-tip="test"
                          className="helpIco"
                          currentitem="false"
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>{" "}
                        <a href="#" className="sortLink">
                          <i className="fas fa-sort"></i>
                        </a>
                      </th>
                      <th
                        className="ColSticky"
                        align="center"
                        valign="middle"
                        scope="col"
                      >
                        Accept Offer
                      </th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {ordersLoading ? (
                    <tr>
                      <td colSpan="9" align="middle">
                        <ReactLoaderSpinner
                          type="Bars"
                          color="#fff"
                          height={50}
                          width={150}
                        />
                      </td>
                    </tr>
                  ) : otcOrders[chainSelected] ? (
                    filteredOtcOrders
                      .filter(
                        (ele) =>
                          position === null ||
                          (position !== null &&
                            ele.liquidityPosition === position)
                      )
                      .map((ele, key) => {
                        if (ele.pair === defaultPair)
                          return (
                            <tr key={key}>
                              <td>
                                <a
                                  target="_blank"
                                  href={ele.txLink}
                                  rel="noreferrer"
                                >
                                  {ele.pair}
                                </a>
                              </td>
                              <td>{ele.vestingDate}</td>
                              <td>
                                <i
                                  className={
                                    ele.liquidityPosition === "1st"
                                      ? "fas fa-circle color-green"
                                      : ele.liquidityPosition === "2nd"
                                      ? "fas fa-circle color-yellow"
                                      : "fas fa-circle color-red"
                                  }
                                ></i>
                                {ele.liquidityPosition}
                              </td>
                              <td
                                data-title="Offering Allocation"
                                data-tip={ele.supplyAvailable}
                                data-for="supplyAvailable"
                              >
                                {Number(ele.supplyAvailable)
                                  .toFixed(2)
                                  .toLocaleString("en-US", { currency: "USD" })}
                                <ReactTooltip id="supplyAvailable" />
                              </td>
                              <td
                                data-title="Market Price"
                                data-tip={ele.marketPrice}
                                data-for="marketPrice"
                              >
                                {formatPrice(ele.marketPrice)}{" "}
                                {nativeTokenList[chainSelected].symbol}
                                <ReactTooltip id="marketPrice" />
                                <Block className="color-dull">
                                  [${formatPrice(ele.marketPriceUSD)}]
                                </Block>
                              </td>
                              <td
                                data-title="Offering Price"
                                data-html="true"
                                data-tip={
                                  ele.otcPrice +
                                  " " +
                                  nativeTokenList[chainSelected].symbol +
                                  " <br/> $" +
                                  ele.otcPriceUSD
                                }
                                data-for="otcPrice"
                                className={
                                  !showBid
                                    ? "color-green highlighted"
                                    : "color-red highlighted"
                                }
                              >
                                {formatPrice(ele.otcPrice)}{" "}
                                {nativeTokenList[chainSelected].symbol}
                                <ReactTooltip id="otcPrice" />
                                <Block
                                  className={
                                    !showBid
                                      ? "color-green color-dull"
                                      : "color-red color-dull"
                                  }
                                >
                                  [${formatPrice(ele.otcPriceUSD)}]
                                </Block>
                              </td>
                              <td
                                data-title="Price Difference"
                                className={
                                  ele.competitiveValueUSD < 0
                                    ? "color-red"
                                    : "color-green"
                                }
                              >
                                ${formatPrice(ele.competitiveValueUSD)} (
                                {formatPrice(ele.competitiveValuePercentage)}%)
                              </td>
                              <td
                                className="ColSticky"
                                data-title="Accept Offer"
                              >
                                <div className="OpfyBTNbar v2">
                                  {account !== null ? (
                                    account !== ele.seller ? (
                                      <button
                                        className="btn01"
                                        onClick={() => {
                                          props.setPopupMyofferClaim(true);
                                          let tokenLogo =
                                            otcTradersData[chainSelected].find(
                                              (e) => e.pairNames == defaultPair
                                            ).tokenLogo !== ""
                                              ? otcTradersData[
                                                  chainSelected
                                                ].find(
                                                  (e) =>
                                                    e.pairNames == defaultPair
                                                ).tokenLogo
                                              : new_token;
                                          props.setCurrentClaimOrder({
                                            ...ele,
                                            tokenLogo,
                                          });
                                        }}
                                      >
                                        ACCEPT
                                      </button>
                                    ) : (
                                      <button className="btn01">
                                        Your request
                                      </button>
                                    )
                                  ) : (
                                    <button
                                      className="btn01"
                                      onClick={() => props.connectWallet()}
                                    >
                                      Connect Wallet
                                    </button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                      })
                  ) : (
                    <tr>
                      <td colSpan="9" align="middle">
                        No transaction
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : (
              <>
                {ordersLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <ReactLoaderSpinner
                      type="Bars"
                      color="#fff"
                      height={50}
                      width={150}
                    />
                  </div>
                ) : (
                  <Grid showPairs={isOpen} className="height3">
                    {otcOrders[chainSelected] ? (
                      filteredOtcOrders
                        .filter(
                          (ele) =>
                            position === null ||
                            (position !== null &&
                              ele.liquidityPosition === position)
                        )
                        .map((ele, key) => {
                          if (ele.pair === defaultPair)
                            return (
                              <Item showBid={showBid}>
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "15px",
                                  }}
                                >
                                  <a
                                    target="_blank"
                                    href={ele.txLink}
                                    rel="noreferrer"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      color: "white",
                                      fontSize: "18px",
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: "40px",
                                        marginRight: "5px",
                                      }}
                                      src={
                                        otcTradersData[chainSelected].find(
                                          (e) => e.pairNames == defaultPair
                                        ).tokenLogo !== ""
                                          ? otcTradersData[chainSelected].find(
                                              (e) => e.pairNames == defaultPair
                                            ).tokenLogo
                                          : new_token
                                      }
                                    ></img>{" "}
                                    {ele.pair}
                                  </a>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i
                                      style={{ marginRight: "10px" }}
                                      className={
                                        ele.liquidityPosition === "1st"
                                          ? "fas fa-circle color-green"
                                          : ele.liquidityPosition === "2nd"
                                          ? "fas fa-circle color-yellow"
                                          : "fas fa-circle color-red"
                                      }
                                    ></i>
                                    {ele.liquidityPosition}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    minHeight: "115px",
                                    display: "flex",
                                    padding: "15px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "50%",
                                      borderRight: "1px solid #1e2127",
                                    }}
                                  >
                                    <span className="color-dull">
                                      Offering allocation
                                    </span>
                                    <span
                                      className="color-white"
                                      style={{
                                        margin: "5px 0px",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {Number(
                                        Number(ele.supplyAvailable).toFixed(2)
                                      ).toLocaleString("en-US", {
                                        currency: "USD",
                                      }) +
                                        " " +
                                        defaultPair.split("/")[0]}
                                    </span>
                                    <span
                                      className="color-dull"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {
                                        nativeTokenList[chainSelected]
                                          .networkName
                                      }{" "}
                                      chain
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      textAlign: "end",
                                      width: "50%",
                                    }}
                                  >
                                    <span className="color-dull">
                                      Asking price/token
                                    </span>
                                    <span
                                      className="color-white"
                                      style={{
                                        margin: "5px 0px",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {formatPrice(ele.otcPrice)}{" "}
                                      {nativeTokenList[chainSelected].symbol}
                                    </span>
                                    <Block
                                      className="color-dull"
                                      style={{ fontSize: "12px" }}
                                    >
                                      [${formatPrice(ele.otcPriceUSD)}]
                                    </Block>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderTop: "1px solid #1e2127",
                                    padding: "15px 20px",
                                  }}
                                >
                                  <button
                                    className="btn"
                                    onClick={() => {
                                      props.setPopupMyofferClaim(true);
                                      let tokenLogo =
                                        otcTradersData[chainSelected].find(
                                          (e) => e.pairNames == defaultPair
                                        ).tokenLogo !== ""
                                          ? otcTradersData[chainSelected].find(
                                              (e) => e.pairNames == defaultPair
                                            ).tokenLogo
                                          : new_token;
                                      props.setCurrentClaimOrder({
                                        ...ele,
                                        tokenLogo,
                                      });
                                    }}
                                  >
                                    {!showBid
                                      ? "BUY " + defaultPair.split("/")[0]
                                      : "SELL " + defaultPair.split("/")[1]}
                                  </button>
                                </div>
                              </Item>
                            );
                        })
                    ) : (
                      <span>No transaction</span>
                    )}
                  </Grid>
                )}
              </>
            )}
          </TableMain>
          <TableBottom>
            <button
              onClick={() => {
                props.setPopupOTCClaim(true);
              }}
            >
              <h5 className="color-green">List OTC</h5>
            </button>
            <Tnav>
              Page
              <SelectCustom>
                <select>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                </select>
              </SelectCustom>
              result 1-10 of 300
            </Tnav>
          </TableBottom>
        </div>
      </ExchangeMain>

      <TabNav className="wow fadeInUp" data-wow-delay="0.1s">
        <a
          className={`tab-Link ${getActiveClass(1, "active")}`}
          onClick={() => toggleTab(1)}
        >
          <span>My active offers</span>
        </a>
        <a
          className={`tab-Link ${getActiveClass(2, "active")}`}
          onClick={() => toggleTab(2)}
        >
          <span>History purchases</span>
        </a>
        <a
          className={`tab-Link ${getActiveClass(3, "active")}`}
          onClick={() => toggleTab(3)}
        >
          History selling
        </a>
      </TabNav>
      <MobileTab>
        <h3>OTC Trades</h3>
        <div className="dropDown">
          <a
            className={`tab-Link ${getActiveClass(1, "active")}`}
            onClick={() => toggleTab(1)}
          >
            <span>My active offers</span>
          </a>
          <a
            className={`tab-Link ${getActiveClass(2, "active")}`}
            onClick={() => toggleTab(2)}
          >
            <span>History purchases</span>
          </a>
          <a
            className={`tab-Link ${getActiveClass(3, "active")}`}
            onClick={() => toggleTab(3)}
          >
            History selling
          </a>
        </div>
      </MobileTab>
      {getActiveClass(
        1,
        <>
          <TabContainer>
            <TableMain className="height1">
              <table
                className="border-cell"
                width="100%"
                border="0"
                cellSpacing="0"
                cellPadding="20"
              >
                <thead>
                  <tr>
                    <th width="12.5%" align="left" valign="middle" scope="col">
                      Token Pairs{" "}
                      <a href="#" className="sortLink">
                        <i className="fas fa-sort"></i>
                      </a>
                    </th>
                    <th width="12.5%" align="left" valign="middle" scope="col">
                      Vesting Due Date{" "}
                      <a href="#" className="sortLink">
                        <i className="fas fa-sort"></i>
                      </a>
                    </th>
                    <th width="12.5%" align="left" valign="middle" scope="col">
                      Offering Allocation
                      <a href="#" className="sortLink">
                        <i className="fas fa-sort"></i>
                      </a>
                    </th>
                    <th width="12.5%" align="left" valign="middle" scope="col">
                      Asking Amount
                      <span
                        data-html="true"
                        data-tip="test"
                        className="helpIco"
                        currentitem="false"
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                      <a href="#" className="sortLink">
                        <i className="fas fa-sort"></i>
                      </a>
                    </th>
                    {/* <th width='12.5%' align='left' valign='middle' scope='col'>
                    Supply Available{' '}
                    <a href='#' className='sortLink'>
                      <i className='fas fa-sort'></i>
                    </a>
                  </th> */}
                    <th width="12.5%" align="left" valign="middle" scope="col">
                      Offering Price
                      <a href="#" className="sortLink">
                        <i className="fas fa-sort"></i>
                      </a>
                    </th>
                    <th width="12.5%" align="left" valign="middle" scope="col">
                      Market Price{" "}
                      <a href="#" className="sortLink">
                        <i className="fas fa-sort"></i>
                      </a>
                    </th>

                    {/* <th width='12.5%' align='left' valign='middle' scope='col'>
                    OTC Price{' '}
                    <a href='#' className='sortLink'>
                      <i className='fas fa-sort'></i>
                    </a>
                  </th> */}
                    {/* <th width='12.5%' align='left' valign='middle' scope='col'>
                    Competitive Value{' '}
                    <span
                      data-html='true'
                      data-tip='test'
                      className='helpIco'
                      currentitem='false'
                    >
                      <i className='fas fa-question-circle'></i>
                    </span>{' '}
                    <a href='#' className='sortLink'>
                      <i className='fas fa-sort'></i>
                    </a>
                  </th> */}
                    <th
                      width="12.5%"
                      align="center"
                      valign="middle"
                      scope="col"
                    >
                      Price Difference
                    </th>
                    <th
                      width="12.5%"
                      align="center"
                      valign="middle"
                      scope="col"
                    >
                      Delist
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {ordersLoading ? (
                    <tr>
                      <td colSpan="9" align="middle">
                        <ReactLoaderSpinner
                          type="Bars"
                          color="#fff"
                          height={50}
                          width={150}
                        />
                      </td>
                    </tr>
                  ) : offersByMe[chainSelected] ? (
                    offersByMe[chainSelected].map((ele, key) => {
                      return (
                        <tr key={key}>
                          <td>
                            <a
                              target="_blank"
                              href={ele.txLink}
                              rel="noreferrer"
                            >
                              {ele.pair}
                            </a>
                          </td>
                          <td>{ele.vestingDate}</td>
                          <td data-tip={ele.supplyAvailable}>
                            {formatPrice(ele.supplyAvailable)}
                          </td>
                          {/* <td data-title="Offering Allocation" className=''></td> */}
                          <td data-tip={ele.askingAmount}>
                            {ele.askingAmount}{" "}
                            <Block className="color-dull">
                              [${formatPrice(ele.askingAmountUSD)}]
                            </Block>
                          </td>
                          <td
                            data-title="Offering Price"
                            data-html="true"
                            data-tip={
                              ele.otcPrice + " <br/> $" + ele.otcPriceUSD
                            }
                            data-for="otcPrice"
                          >
                            {formatPrice(ele.otcPrice)}{" "}
                            {nativeTokenList[chainSelected].symbol}
                            <ReactTooltip id="otcPrice" />
                            <Block className="color-dull">
                              [${formatPrice(ele.otcPriceUSD)}]
                            </Block>
                          </td>
                          <td
                            data-title="Market Price"
                            data-tip={ele.marketPrice}
                            data-for="marketPrice"
                          >
                            {formatPrice(ele.marketPrice)}{" "}
                            {nativeTokenList[chainSelected].symbol}
                            <ReactTooltip id="marketPrice" />
                            <Block className="color-dull">
                              [${formatPrice(ele.marketPriceUSD)}]
                            </Block>
                          </td>
                          {/* <td data-title="Offering Price" className=''></td> */}
                          {/* Use color-green class if value is + and color-red if - */}
                          {/* <td data-title="Price Difference"></td> */}

                          <td
                            className={
                              ele.competitiveValueUSD < 0
                                ? "color-red"
                                : "color-green"
                            }
                          >
                            ${formatPrice(ele.competitiveValueUSD)} |{" "}
                            {formatPrice(ele.competitiveValuePercentage)}%
                          </td>

                          <td className="clickable">
                            <a
                              className="btn02"
                              onClick={() => handleCancelOrder(ele)}
                            >
                              {cancelLoading ? (
                                <ReactLoaderSpinner
                                  type="ThreeDots"
                                  color="#fff"
                                  height={15}
                                  width={80}
                                />
                              ) : (
                                "DELIST"
                              )}
                            </a>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="9" align="middle">
                        No transaction
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </TableMain>
          </TabContainer>

          {/* Table V2 */}
          {/* <TabContainer>
          <TableMain className='height1'>
            <table
              className='border-cell'
              width='100%'
              border='0'
              cellSpacing='0'
              cellPadding='20'
            >
              <thead>
                <tr>
                  <th width='12.5%' align='left' valign='middle' scope='col'>
                    Token Pairs{' '}
                    <a href='#' className='sortLink'>
                      <i className='fas fa-sort'></i>
                    </a>
                  </th>
                  <th width='12.5%' align='left' valign='middle' scope='col'>
                    Vesting Due Date{' '}
                    <a href='#' className='sortLink'>
                      <i className='fas fa-sort'></i>
                    </a>
                  </th>
                  <th width='12.5%' align='left' valign='middle' scope='col'>
                    Budged Available
                    <a href='#' className='sortLink'>
                      <i className='fas fa-sort'></i>
                    </a>
                  </th>
                  <th width='12.5%' align='left' valign='middle' scope='col'>
                    Asking Allocation
                    <span
                      data-html='true'
                      data-tip='test'
                      className='helpIco'
                      currentitem='false'
                    >
                      <i className='fas fa-question-circle'></i>
                    </span>
                    <a href='#' className='sortLink'>
                      <i className='fas fa-sort'></i>
                    </a>
                  </th>
                  <th width='12.5%' align='left' valign='middle' scope='col'>
                    Bid Price{' '}
                    <a href='#' className='sortLink'>
                      <i className='fas fa-sort'></i>
                    </a>
                  </th>
                  <th width='12.5%' align='left' valign='middle' scope='col'>
                    Market Price{' '}
                    <a href='#' className='sortLink'>
                      <i className='fas fa-sort'></i>
                    </a>
                  </th>
                  
                  <th width='12.5%' align='left' valign='middle' scope='col'>
                    OTC Price{' '}
                    <a href='#' className='sortLink'>
                      <i className='fas fa-sort'></i>
                    </a>
                  </th>
                  <th width='12.5%' align='left' valign='middle' scope='col'>
                    Competitive Value{' '}
                    <span
                      data-html='true'
                      data-tip='test'
                      className='helpIco'
                      currentitem='false'
                    >
                      <i className='fas fa-question-circle'></i>
                    </span>{' '}
                    <a href='#' className='sortLink'>
                      <i className='fas fa-sort'></i>
                    </a>
                  </th>
                  <th width='12.5%' align='center' valign='middle' scope='col'>Price Difference <a href='#' className='sortLink'>
                      <i className='fas fa-sort'></i>
                    </a></th>
                  <th width='12.5%' align='center' valign='middle' scope='col'>
                    Delist
                  </th>
                </tr>
              </thead>
              <tbody>
                {ordersLoading ? (
                  <tr>
                    <td colSpan='9' align='middle'>
                      <ReactLoaderSpinner
                        type='Bars'
                        color='#fff'
                        height={50}
                        width={150}
                      />
                    </td>
                  </tr>
                ) : offersByMe.length > 0 ? (
                  offersByMe.map((ele, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          <a target='_blank' href={ele.txLink}>
                            {ele.pair}
                          </a>
                        </td>
                        <td>{ele.vestingDate}</td>
                        <td data-tip={ele.supplyAvailable}>
                          {Number(ele.supplyAvailable).toFixed(2)}
                        </td>
                        <td data-title="Offering Allocation" className=''></td>
                        <td data-tip={ele.askingAmount}>
                          {ele.askingAmount}{' '}
                          <Block className='color-dull'>
                            [${ele.askingAmountUSD.toFixed(2)}]
                          </Block>
                        </td>
                        <td>
                          {ele.marketPrice.toFixed(4)} BNB
                          <Block className='color-dull'>
                            [${ele.marketPriceUSD.toFixed(2)}]
                          </Block>
                        </td>
                        <td data-title="Offering Price" className=''></td>
                        <td>
                          {ele.otcPrice.toFixed(4)}
                          <Block className='color-dull'>
                            [${ele.otcPriceUSD.toFixed(4)}]
                          </Block>
                        </td>
                        Use color-green class if value is + and color-red if -
                        <td data-title="Price Difference"></td>
                        
                        <td
                          className={
                            ele.competitiveValueUSD < 0
                              ? 'color-red'
                              : 'color-green'
                          }
                        >
                          ${ele.competitiveValueUSD.toFixed(2)} |{' '}
                          {ele.competitiveValuePercentage.toFixed(2)}%
                        </td>

                        <td className='clickable'>
                          <a
                            className='btn02'
                            onClick={() => handleCancelOrder(ele.orderId)}
                          >
                            {cancelLoading ? (
                              <ReactLoaderSpinner
                                type='ThreeDots'
                                color='#fff'
                                height={15}
                                width={80}
                              />
                            ) : (
                              'DELIST'
                            )}
                          </a>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan='9' align='middle'>
                      No transaction
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </TableMain>
        </TabContainer> */}
        </>
      )}
      {getActiveClass(
        2,
        <TabContainer>
          <TableMain className="height1">
            <table
              className="border-cell"
              width="100%"
              border="0"
              cellSpacing="0"
              cellPadding="20"
            >
              <thead>
                <tr>
                  <th width="14%" align="left" valign="middle" scope="col">
                    Token Pairs{" "}
                    <a href="#" className="sortLink">
                      <i className="fas fa-sort"></i>
                    </a>
                  </th>
                  <th width="14%" align="left" valign="middle" scope="col">
                    Vesting Due Date{" "}
                    <a href="#" className="sortLink">
                      <i className="fas fa-sort"></i>
                    </a>
                  </th>
                  <th width="14%" align="left" valign="middle" scope="col">
                    Allocation Purchased{" "}
                    <a href="#" className="sortLink">
                      <i className="fas fa-sort"></i>
                    </a>
                  </th>
                  <th width="14%" align="left" valign="middle" scope="col">
                    Amount Paid{" "}
                    <a href="#" className="sortLink">
                      <i className="fas fa-sort"></i>
                    </a>
                  </th>
                  <th width="14%" align="left" valign="middle" scope="col">
                    Purchase Price{" "}
                    <a href="#" className="sortLink">
                      <i className="fas fa-sort"></i>
                    </a>
                  </th>
                  <th width="14%" align="left" valign="middle" scope="col">
                    OTC Price{" "}
                    <a href="#" className="sortLink">
                      <i className="fas fa-sort"></i>
                    </a>
                  </th>
                  {/* <th width='16.6%' align='left' valign='middle' scope='col'>
                    Supply Sold{' '}
                    <a href='#' className='sortLink'>
                      <i className='fas fa-sort'></i>
                    </a>
                  </th> */}
                  {/* <th width='16.6%' align='left' valign='middle' scope='col'>
                    Amount Received
                    <span
                      data-html='true'
                      data-tip='test'
                      className='helpIco'
                      currentitem='false'
                    >
                      <i className='fas fa-question-circle'></i>
                    </span>
                    <a href='#' className='sortLink'>
                      <i className='fas fa-sort'></i>
                    </a>
                  </th> */}
                  {/* <th width='16.6%' align='left' valign='middle' scope='col'>
                    OTC Price{' '}
                    <a href='#' className='sortLink'>
                      <i className='fas fa-sort'></i>
                    </a>
                  </th> */}
                  <th width="14%" align="left" valign="middle" scope="col">
                    Proof
                  </th>
                </tr>
              </thead>
              <tbody>
                {ordersLoading ? (
                  <tr>
                    <td colSpan="9" align="middle">
                      <ReactLoaderSpinner
                        type="Bars"
                        color="#fff"
                        height={50}
                        width={150}
                      />
                    </td>
                  </tr>
                ) : historyPurchases[chainSelected] ? (
                  historyPurchases[chainSelected].map((ele, key) => {
                    return ele.boughtOrders.length > 0
                      ? ele.boughtOrders.map((subEle, key) => {
                          if (
                            subEle.buyer.toLocaleLowerCase() ===
                            account.toLocaleLowerCase()
                          ) {
                            return (
                              <tr key={key}>
                                <td>{ele.pair}</td>
                                <td>{ele.vestingDate}</td>
                                <td>
                                  {formatPrice(
                                    Web3.utils.fromWei(subEle.boughtAmount)
                                  )}
                                </td>
                                <td>
                                  {formatPrice(
                                    Web3.utils.fromWei(subEle.paidAmount)
                                  )}
                                </td>
                                <td>
                                  {formatPrice(ele.marketPrice)}
                                  <Block className="color-dull">
                                    [${formatPrice(ele.marketPrice)}]
                                  </Block>
                                </td>
                                <td>
                                  {formatPrice(ele.otcPrice)}
                                  <Block className="color-dull">
                                    [${formatPrice(ele.otcPriceUSD)}]
                                  </Block>
                                </td>
                                <td>
                                  <a
                                    target="_blank"
                                    href={
                                      explorerLink[ele.chainId] +
                                      "/tx/" +
                                      subEle.transactionHash
                                    }
                                    rel="noreferrer"
                                  >
                                    {subEle.transactionHash.slice(0, 8) +
                                      ".." +
                                      subEle.transactionHash.slice(60, 66)}
                                  </a>
                                </td>
                              </tr>
                            );
                          } else {
                            return null;
                          }
                        })
                      : null;
                  })
                ) : (
                  <tr>
                    <td colSpan="9" align="middle">
                      No transaction
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </TableMain>
        </TabContainer>
      )}
      {getActiveClass(
        3,
        <TabContainer>
          <TableMain className="height1">
            <table
              className="border-cell"
              width="100%"
              border="0"
              cellSpacing="0"
              cellPadding="20"
            >
              <thead>
                <tr>
                  <th width="14%" align="left" valign="middle" scope="col">
                    Token Pairs{" "}
                    <a href="#" className="sortLink">
                      <i className="fas fa-sort"></i>
                    </a>
                  </th>
                  <th width="14%" align="left" valign="middle" scope="col">
                    Vesting Due Date{" "}
                    <a href="#" className="sortLink">
                      <i className="fas fa-sort"></i>
                    </a>
                  </th>
                  <th width="14%" align="left" valign="middle" scope="col">
                    Allocation Sold{" "}
                    <a href="#" className="sortLink">
                      <i className="fas fa-sort"></i>
                    </a>
                  </th>
                  <th width="14%" align="left" valign="middle" scope="col">
                    Amount Received
                    <span
                      data-html="true"
                      data-tip="test"
                      className="helpIco"
                      currentitem="false"
                    >
                      <i className="fas fa-question-circle"></i>
                    </span>
                    <a href="#" className="sortLink">
                      <i className="fas fa-sort"></i>
                    </a>
                  </th>
                  {/* <th width='14%' align='left' valign='middle' scope='col'>
                    Supply Sold{' '}
                    <a href='#' className='sortLink'>
                      <i className='fas fa-sort'></i>
                    </a>
                  </th> */}
                  <th width="14%" align="left" valign="middle" scope="col">
                    Selling Price{" "}
                    <a href="#" className="sortLink">
                      <i className="fas fa-sort"></i>
                    </a>
                  </th>
                  <th width="14%" align="left" valign="middle" scope="col">
                    OTC Price{" "}
                    <a href="#" className="sortLink">
                      <i className="fas fa-sort"></i>
                    </a>
                  </th>
                  <th width="14%" align="left" valign="middle" scope="col">
                    Proof
                  </th>
                </tr>
              </thead>
              <tbody>
                {ordersLoading ? (
                  <tr>
                    <td colSpan="9" align="middle">
                      <ReactLoaderSpinner
                        type="Bars"
                        color="#fff"
                        height={50}
                        width={150}
                      />
                    </td>
                  </tr>
                ) : historySelling[chainSelected] ? (
                  historySelling[chainSelected].map((ele, key) => {
                    return (
                      <tr key={key}>
                        <td>{ele.pair}</td>
                        <td>{ele.vestingDate}</td>
                        {ele.boughtOrders.length > 0
                          ? ele.boughtOrders.map((subEle, key) => {
                              return (
                                <>
                                  <td data-title="Allocation Sold">
                                    {formatPrice(
                                      Web3.utils.fromWei(subEle.boughtAmount)
                                    )}
                                  </td>
                                  <td>
                                    {formatPrice(
                                      Web3.utils.fromWei(subEle.paidAmount)
                                    )}
                                    {/* <Block className='color-dull'>[$0.01986]</Block> */}
                                  </td>
                                  <td>
                                    0.0006{" "}
                                    {nativeTokenList[chainSelected].symbol}
                                    <Block className="color-dull">
                                      [$0.01986]
                                    </Block>
                                  </td>
                                  {/* <td>
                                    {Web3.utils.fromWei(subEle.paidAmount)}
                                  </td> */}
                                  <td>
                                    {formatPrice(ele.otcPrice)}
                                    <Block className="color-dull">
                                      [${formatPrice(ele.otcPriceUSD)}]
                                    </Block>
                                  </td>
                                  <td>
                                    <a
                                      target="_blank"
                                      href={
                                        explorerLink[ele.chainId] +
                                        "/tx/" +
                                        subEle.transactionHash
                                      }
                                      rel="noreferrer"
                                    >
                                      {subEle.transactionHash.slice(0, 8) +
                                        ".." +
                                        subEle.transactionHash.slice(60, 66)}
                                    </a>
                                  </td>
                                </>
                              );
                            })
                          : null}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="9" align="middle">
                      No transaction
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </TableMain>
        </TabContainer>
      )}
    </>
  );
}

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;
const Height = styled.div`
  height: 500px;
`;

const Filter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 20px 0;
  position: relative;
  .btn01 {
    width: 155px;
    font-weight: 700;
    height: 40px;
    border: 2px solid #00f02b;
    box-shadow: 0 0 8px #00f02b;
    border-radius: 5px;
    color: #00f02b;
    :hover {
      color: #000;
      background-color: #00f02b;
    }
  }
  .filter-icon {
    display: flex;
    color: #fff;
    align-items: center;
    margin-right: 18px;
    padding: 0 17px;
    border-right: 1px solid #1e2127;
    &.active {
      color: #00f02b;
    }
  }
`;
const FilterBox = styled.div`
  border: 1px solid #1e2127;
  width: 20%;
  padding: 20px 18px;
  position: relative;
  &:after {
    content: "";
    top: 0;
    bottom: 0;
    right: -12px;
    position: absolute;
    width: 1px;
    background: #1e2127;
  }
  h4 {
    font-size: 14px;
    color: #9a9a9a;
    margin: 0;
  }
  .searchBox {
    position: relative;
    margin: 16px 0;
    i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 14px;
    }
  }
  .search {
    width: 100%;
    height: 42px;
    background: none;
    border: 1px solid #1e2127;
    border-radius: 0;
    padding: 0 0 0 40px;
    color: #9a9a9a;
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      font-size: 14px;
      list-style: none;
      margin-bottom: 16px;
    }
    &.checkList {
      margin-top: 24px;
    }
  }

  &.discount {
    /* background: #1e2127; */
    &:after {
      display: none;
    }
    & + & {
      margin-top: 12px;
    }
  }
`;
const FilterMain = styled.div`
  background: #000;
  height: 317px;
  display: flex;
  gap: 20px;
  margin: 0 -55px;
  padding: 20px 55px;
  border-top: 1px solid #1e2127;
  border-bottom: 1px solid #1e2127;
  .hasFilter {
    width: 20%;
    display: flex;
    flex-flow: column;
    ${FilterBox} {
      width: 100%;
      flex-grow: 1;
    }
  }
`;

const MaxMin = styled.div`
  display: flex;
  align-items: center;
  span {
    flex-grow: 1;
    text-align: center;
    margin: 0 5px;
  }
  .textBox {
    height: 42px;
    width: 77px;
    flex-grow: 1;
    background: none;
    border: 1px solid #1e2127;
    border-radius: 0;
    margin: 16px 0;
    padding: 0 15px;
    color: #9a9a9a;
  }
`;
const Discount = styled.div`
  display: flex;
  // flex-flow: column;
  text-align: center;
  margin-top: 15px;
  align-items: center;
  .textBox {
    height: 42px;
    width: 100%;
    flex-grow: 1;
    background: none;
    border: 1px solid #1e2127;
    border-radius: 0;
    padding: 0 10px;
    color: #fff;
  }
  span {
    margin: 10px 4px;
    font-size: 12px;
  }
  .hasBox {
    position: relative;
  }
  strong {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1e2127;
    color: #9a9a9a;
    &.xl {
      width: 60px;
    }
  }
`;
const ExchangeMain = styled.div`
  display: flex;
  padding: 25px 0 0;
  ${Media.lg} {
    align-items: flex-end;
  }
  ${Media.md2} {
    flex-flow: column;
  }
  .ChainsList {
    width: 100px;
    flex-shrink: 0;
    background: #000;
    margin-right: 0;
    border: 1px solid #1e2127;
    height: 625px;
    overflow: auto;
    a {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      height: 90px;
      width: 100%;
      cursor: pointer;
      transition: all 0.3s ease-in-out 0s;
      border-bottom: 1px solid #1e2127;
      &:last-child {
        border-bottom: 0;
      }
      span {
        margin-top: 16px;
        text-align: center;
        font-size: 12px;
      }
      &:hover {
        background: #191c22;
      }
      &.active {
        background: #191c22;
      }
    }
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background: #000;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: #1e2127; /* outline: 3px solid #1e2127; */
      box-shadow: inset 0 0 0 3px #000;
    }
    ${Media.md2} {
      flex-flow: row;
      width: 100%;
      display: flex;
      height: auto;
      a {
        width: 100px;
        flex-shrink: 0;
        border-right: 1px solid #1e2127;
        border-bottom: 0px;
        flex-grow: 1;
      }
    }
  }
  .leftCol {
    margin-right: 23px;
    ${Media.md2} {
      width: 100%;
      margin: 0;
    }
  }
  .centerCol {
    width: 435px;
    flex-shrink: 0;
    img {
      width: 21px;
      height: 21px;
      object-fit: contain;
    }
    th {
      text-align: left;
    }
    td {
      padding-left: 14px;
      padding-right: 14px;
    }
    ${Media.lg} {
      width: 353px;
    }
    ${Media.md2} {
      width: 100%;
    }
  }
  .rightCol {
    flex-grow: 1;
    padding-left: 23px;
    max-width: calc(100% - 560px);
    .actionIcon.push {
      margin-left: -30px;
      height: 20px;
      margin-right: 10px;
      transform: scaleX(-1);
      opacity: 0;
      visibility: hidden;
    }
    &.expand {
      padding-left: 0;
      max-width: 100%;
      .actionIcon.push {
        opacity: 1;
        visibility: visible;
      }
    }
    ${Media.lg} {
      width: calc(100% - 600px);
      max-width: inherit;
    }
    ${Media.md2} {
      width: 100%;
      padding: 0;
    }
    ${Media.md} {
      .actionIcon.v2 {
      }
    }
  }
  .clickable {
    :hover {
      cursor: pointer;
    }
  }
`;
const TableMain = styled.div`
  position: relative;
  overflow: auto;
  max-width: 100%;
  max-height: 500px;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: #1e2127;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0a0c0e;
    /* outline: 3px solid #1e2127; */
    box-shadow: inset 0 0 0 2px #1e2127;
  }
  &.height1 {
    max-height: 281px;
    min-height: 281px;
  }
  &.height2 {
    max-height: 281px;
  }
  &.height3 {
    max-height: 622px;
    min-height: 622px;
  }
  table {
    width: 100%;
    background: #000;
    max-width: 100%;
    border: 1px solid #1e2127;
    border-collapse: separate;
    thead {
      position: sticky;
      top: 0;
      z-index: 5;
      th {
        position: sticky;
        top: 0;
        left: 0;
        border-top: 1px solid #1e2127;
        border-bottom: 1px solid #1e2127;
        border-right: 1px solid #1e2127;
        &:last-child {
          border-right: 0;
        }
        &.highlighted {
          background: #191c22;
        }
        &.ColSticky {
          right: 0px;
          position: sticky;
          border-right: 1px solid #1e2127 !important;
          padding-right: 10px;
          ${Media.lg} {
            box-shadow: -6px 5px 10px 0 rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
    td {
      border-top: 1px solid #1e2127;
    }
    &.border-cell {
      td {
        border-bottom: 0 solid #1e2127;
        border-right: 1px solid #1e2127;
        &:first-child {
          a {
            color: #fff;
            white-space: nowrap;
          }
        }
        &:last-child {
          border-right: 0;
          a:not(.btn02) {
            color: #3131ff;
          }
        }
        &.ColSticky {
          right: 0;
          border-right: 1px solid #1e2127 !important;
          position: sticky;
          background: #000;
          overflow: hidden;
          /* &:after {content: ""; background: #000; z-index: 1; position: absolute; top: 0; left: 0; right: 0; bottom: 0;} */
          ${Media.lg} {
            box-shadow: -6px 5px 10px 0 rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
  }
  table tr td {
    padding: 10px 18px;
    color: #fff;
    height: 60px;
    &.bor01 {
      border-right: 5px solid #1e2127;
    }
    &.bor02 {
      color: #4b4bff;
    }
    &.highlighted {
      background: #191c22;
    }
    ${Media.md} {
      padding: 10px 10px;
    }
    .fa-circle {
      font-size: 8px;
      line-height: 16px;
      vertical-align: top;
      margin-right: 8px;
    }
  }

  table tr:hover td {
    background-color: #010202;
  }
  table tr th {
    background-color: #000;
    color: #9a9a9a;
    position: relative;
    padding: 12px;
    height: 60px;
    padding-right: 27px;
    a {
      color: #9a9a9a;
      font-size: 18px;
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
    &:nth-last-child(01) {
    }
    &:nth-child(01) {
    }
    &.bor01 {
      border-right: 5px solid #545861;
    }
  }
  .color-dull {
    color: #9a9a9a;
  }
  .color-green {
    color: #00f02b;
    text-shadow: 0 0 8px #00f02b;
  }
  .color-red {
    color: #c32b2d;
    text-shadow: 0 0 8px #ef4547;
  }
  .color-yellow {
    color: #e4a029;
  }
  .color-blue {
    color: #3131ff;
  }
  .btn01 {
    min-width: 102px;
    white-space: nowrap;
    font-weight: 700;
    height: 40px;
    border-radius: 5px;
    color: #00f02b;
    text-shadow: 0 0 8px #00f02b;
    display: inline-block;
    text-align: center;
    line-height: 36px;
    border: 2px solid transparent;
    &:hover {
      border: 2px solid #00f02b;
      box-shadow: 0 0 10px #00f02b;
    }
  }
  .btn02 {
    width: -webkit-fill-available;
    font-weight: 700;
    height: 40px;
    border-radius: 5px;
    color: #c32b2d;
    display: inline-block;
    text-align: center;
    line-height: 36px;
    border: 2px solid transparent;
    &:hover {
      border: 2px solid #c32b2d;
      box-shadow: 0 0 10px #c32b2d;
      cursor: pointer;
    }
  }
`;
const TableTitle = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: flex-end;
  font-size: 14px;
  padding-top: 20px;
  ${Media.sm} {
    margin-bottom: 15px;
  }
  h3 {
    font-size: 18px;
    color: #fff;
    margin: 0;
  }
  .color-dull {
    color: #9a9a9a;
  }
  .bold-white {
    color: #fff;
  }
  .rightTitle {
    margin-left: auto;
    .actionIcon {
      height: 20px;
      margin: -2px 0 0;
      vertical-align: top;
      & + span {
        margin-left: 10px;
      }
    }
    ${Media.md} {
      line-height: 1.6;
      &.v2 .actionIcon {
        transform: rotate(-90deg);
      }
    }
    ${Media.sm} {
      &.v2 {
        margin-left: auto;
      }
    }
  }
  ${Media.lg} {
    flex-flow: wrap;
    .leftTitle {
      order: 1;
    }
    .ttTop {
      order: 3;
      margin-top: 13px;
      p {
        &:first-child {
          border-left: 0;
          padding: 0 0 0 0;
          margin: 0 0 0 0;
        }
      }
    }
    .rightTitle {
      order: 2;
    }
  }
  ${Media.sm} {
    .ttTop {
      flex-flow: wrap;
      margin-top: 20px;
      p {
        display: block;
        width: 100%;
        border: 0;
        padding: 0;
        margin: 0 0 10px;
        &:first-child {
          margin: 0 0 10px;
        }
      }
    }
    .rightTitle {
      order: 3;
      margin-left: 0;
      margin-top: 15px;
      /* .actionIcon {display: none;} */
    }
    .color-dull {
      line-height: 1.5;
      margin-top: 8px;
    }
  }
`;
const Block = styled.div`
  display: block;
`;
const TableBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #9a9a9a;
  padding: 16px 0 0;
  h5 {
    font-size: 12px;
    font-weight: bold;
    color: #9a9a9a;
  }
  .color-green {
    color: #00f02b;
    text-shadow: 0 0 8px #00f02b;
  }
`;
const Tnav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #9a9a9a;

  p {
    color: #9a9a9a;
  }
  a {
    color: #aaa;
    margin: 0 10px;
  }
  a:hover {
    color: #91dc27;
  }
`;
const SelectCustom = styled.div`
  display: inline-block;
  position: relative;
  margin: 0 10px;
  select {
    background: #010202;
    min-width: 80px;
    color: #fff;
    font-size: 12px;
    border: 0;
    height: 30px;
    border-radius: 4px;
    padding: 0 10px;
    appearance: none;
  }
  &:after {
    content: "\f0d7";
    font-family: "Font Awesome 5 Free";
    color: #aaa;
    font-weight: 900;
    position: absolute;
    right: 5px;
    top: 8px;
  }
`;

const TabNav = styled.div`
  padding-top: 50px;
  flex-direction: row;
  display: flex;
  .tab-Link {
    font-size: 21px;
    font-weight: 700;
    color: #9a9a9a;
    flex-grow: 1;
    text-align: center;
    border-bottom: 3px solid #373b44;
    padding-bottom: 30px;
    position: relative;
    i {
      position: absolute;
      top: -3px;
      margin-left: 3px;
      i {
        font-size: 14px;
        color: #000;
        width: 23px;
        height: 23px;
        background-color: #00f02b;
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    }
    span {
      position: relative;
    }
    ${Media.md} {
      font-size: 18px;
    }
    ${Media.sm} {
      font-size: 14px;
      padding: 15px 0px;
    }
  }
  ${Media.md} {
    display: none;
  }
  ${Media.sm} {
    align-items: stretch;
  }
  .tab-Link.active {
    color: #fff;
    border-bottom-color: #fff;
  }

  .hoverClass:hover span {
    display: none;
  }
  .hoverClass:hover:before {
    content: "Coming Soon";
  }
`;

const Nowrap = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  img {
    margin-right: 8px;
  }
  i {
    margin-right: 6px;
  }
  ${Media.xs} {
    white-space: normal;
    p {
      span {
        display: block;
      }
    }
  }
`;

const TabContainer = styled.div`
  padding: 50px 0 20px;
  .Note {
    text-align: center;
    width: 100%;
    font-size: 18px;
    color: #9a9a9a;
    margin-top: 56px;
  }
  .clickable {
    :hover {
      cursor: pointer;
    }
  }
`;

const SpanButton = styled.span.attrs((props) => ({
  name: props.name,
}))`
  cursor: pointer;
`;
const Tabs = styled.div`
    display: flex; margin-bottom: 0; align-items: center; margin-top: 0; margin-right: auto; 
    a { font-weight: bold;
        &.active {color: #00f02b; text-shadow: 0 0 8px #00f02b}
        &.active.color-red {color: #c32b2d}
    }
    span {margin: 0 6px; color: #9a9a9a;}
    ${Media.sm} {
        margin-top: 0; flex-flow: wrap;
        div {width: 100%; margin-top: 16px;}
    }
    &.v2 {
        a { color: #5a5e67; font-weight: bold;
            &.active {color: #fff}
        }
    }
    .fas.fa-caret-up {font-size: 16px; color: #fff; margin-left: 30px;}
    button { font-weight: bold;
      color: white;
      &.active {color: #00f02b; text-shadow: 0 0 8px #00f02b}
      &.active.color-red {color: #c32b2d}
    }
  }
`;
const ChartContainer = styled.div`
  background: #000;
  border: 1px solid #1e2127;
  padding: 0 30px 15px;
  position: relative;
  margin: 25px 0 40px 0;
  position: relative;
  .apexcharts-tooltip span {
    color: #000;
  }
  .apexcharts-tooltip-title {
    color: #000;
  }
`;
const ProgressMain = styled.div`
  background: #c32b2d;
  box-shadow: 0 0 10px #c32b2d;
  width: 330px;
  height: 24px;
  margin-left: auto;
  position: relative;
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  padding: 0 7px;
`;
const Progress = styled.div`
  background: #00f02b;
  box-shadow: 0 0 10px #00f02b;
  width: 50%;
  height: 24px;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 0 7px;
  &:after {
    content: "";
    position: absolute;
    width: 40px;
    height: 24px;
    left: calc(100% - 32px);
    background: #00f02b;
    transform: skewX(-35deg);
  }
`;
const CheckBoxes = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 18px 0 12px 0;
  .md-checkbox {
    &:not(:last-child) {
      padding-right: 18px;
    }
    label {
      padding-left: 25px;
      font-size: 12px;
      &:before {
        width: 14px;
        height: 14px;
        border-radius: 0;
        top: 2px;
      }
    }
    input[type="checkbox"]:checked + label:after {
      top: 1px;
      left: 4px;
      width: 15px;
      height: 8px;
      border: 3px solid #fff;
      border-top-style: none;
      border-right-style: none;
      font-weight: bold;
    }
  }
`;
const Time = styled.div`
  position: absolute;
  bottom: -38px;
  left: 0;
  display: flex;
  b {
    font-size: 14px;
    padding: 0 15px;
    border-right: 1px solid #000;
    border-left: 1px solid #2a2a2a;
    &:last-child {
      border-right: 0 solid #000;
    }
    &:first-child {
      border-left: 0 solid #2a2a2a;
    }
    &.active {
      color: #00f02b;
    }
  }
`;
const TabBox = styled.div`
  background: #000;
  border: 1px solid #1e2127;
  padding: 30px 28px;
  position: relative;
  margin-bottom: 30px;
  h5 {
    font-size: 21px;
    font-weight: 700;
    margin: 0 0 25px;
  }
  p {
    font-size: 18px;
    line-height: 30px;
    margin: 0 0 36px;
  }
  .close {
    position: absolute;
    top: 0;
    right: 0;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    background: #1e2127;
    font-size: 16px;
    cursor: pointer;
  }
`;
const MobileTab = styled(FlexDiv)`
  padding-top: 40px;
  display: none;
  h3 {
    color: #fff;
    font-size: 18px;
    padding: 15px 0px;
    border-bottom: 3px solid #fff;
    width: 100%;
    position: relative;
    margin: 0;
    &:after {
      content: "";
      border-width: 6px;
      border-style: solid;
      border-bottom: 0px;
      -webkit-transition: 0.5s ease all;
      transition: 0.5s ease all;
      border-color: #fff transparent transparent transparent;
      position: absolute;
      right: 0;
      top: 23px;
    }
  }
  .dropDown {
    width: 100%;
    display: flex;
    flex-flow: column;
    a {
      padding: 15px 0px;
      font-size: 14px;
      border-bottom: 1px solid #393d46;
      color: #fff;
    }
  }
  ${Media.md} {
    display: block;
  }
`;
const ButtonGroup = styled.div`
  border-radius: 5px;
  border: 1px solid #1e2127;
  display: flex;
  flex: row;
  .btn {
    padding: 0px 20px;
    width: -webkit-fill-available;
    font-weight: 700;
    height: 35px;
    border-radius: 5px;
    color: #9a9a9a;
    display: inline-block;
    text-align: center;
    border: 2px solid transparent;
    &:hover {
      cursor: pointer;
    }
    &.active {
      color: #00f02b;
    }
    &.buy {
      color: #00f02b;
    }
    &.sell {
      color: #d22b2b;
    }
  }
`;

const SeachInput = styled.div`
  border-radius: 5px;
  border: 1px solid #1e2127;
  display: flex;
  width: -webkit-fill-available;
  color: #9a9a9a;
  align-items: center;
  padding: 0px 15px;
  .textBox {
    width: -webkit-fill-available;
    background: transparent;
    border: none;
  }
`;
const FilterButton = styled.button`
  width: 300px;
  font-weight: 700;
  height: 40px;
  border: 2px solid #00f02b;
  box-shadow: 0 0 8px #00f02b;
  border-radius: 5px;
  color: #00f02b;
  :hover {
    color: #000;
    background-color: #00f02b;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.showPairs ? "repeat(3, 1fr)" : "repeat(4, 1fr)"};
  grid-gap: 15px;

  position: relative;
  overflow: auto;
  max-width: 100%;
  max-height: 500px;

  &.height3 {
    max-height: 622px;
    min-height: 622px;
  }

  .color-dull {
    color: #9a9a9a;
  }
  .color-green {
    color: #00f02b;
    text-shadow: 0 0 8px #00f02b;
  }
  .color-red {
    color: #c32b2d;
    text-shadow: 0 0 8px #ef4547;
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: #1e2127;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0a0c0e;
    /* outline: 3px solid #1e2127; */
    box-shadow: inset 0 0 0 2px #1e2127;
  }
  ${Media.md} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Item = styled.div`
  border-radius: 5px;
  border: 1px solid #1e2127;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  max-height: 255px;
  .btn {
    width: 100%;
    font-weight: 700;
    height: 40px;
    border: ${(props) =>
      !props.showBid ? "2px solid #00f02b;" : "2px solid #c32b2d;"};
    box-shadow: ${(props) =>
      !props.showBid ? "0 0 8px #00f02b;" : "0 0 8px #c32b2d;"};
    border-radius: 5px;
    color: #fff;
    :hover {
      background-color: ${(props) =>
        !props.showBid ? "#00f02b;" : "#c32b2d;"};
    }
  }
`;
const DDMenu = styled(FlexDiv)`
  justify-content: flex-start;
  position: relative;
  width: 300px;
  border-radius: 5px;
  border: 1px solid #1e2127;
  font-size: 14px;
  font-weight: 400;
  padding: 0 15px;
  align-items: center;
  color: #9a9a9a;
  i {
    max-width: 80px;
  }
  :after {
    content: "";
    border-width: 7px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    right: 16px;
    top: 15px;
  }

  &.formBox {
    input {
      width: 100%;
      height: 64px;
      padding: 6px 0;
      background-color: transparent;
      border: 0px;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }
    :after {
      display: none;
    }

    &.green {
      input {
        color: #00f02b;
        font-size: 18px;
      }
    }
    &.red {
      input {
        color: #c32b2d;
        font-size: 18px;
      }
    }
  }
  &.noBorder {
    :after {
      display: none;
    }
    border: 0px;
  }
  &.ApBX01 {
    position: relative;

    .plsBtn {
      position: absolute;
      left: 4px;
      top: 5px;
      width: 50px;
      height: 50px;
      color: #fff;
      background-color: #5a5e67;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px 0 0 10px;
      :hover {
        background-color: #00f02b;
      }
      i {
        margin: 0;
      }
    }
    .minBtn {
      position: absolute;
      left: 54px;
      top: 5px;
      width: 50px;
      height: 50px;
      color: #fff;
      background-color: #5a5e67;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 10px 10px 0;
      :hover {
        background-color: #c32b2d;
      }
      i {
        margin: 0;
      }
    }

    input {
      padding-left: 120px;
      font-weight: 400;
    }
    span {
      background-color: #5a5e67;
      color: #fff;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      min-width: 60px;
      border-radius: 0 6px 6px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }
  }

  .DDContainer {
    width: 100%;
    z-index: 10;
    position: absolute;
    left: 0;
    top: calc(100% + 6px);
  }
  .DDSubContainer {
    width: 100%;
    color: white;
    border-radius: 10px;
    background-color: rgba(41, 42, 48, 0.95);
    padding: 10px;
    margin: 0 0 8px 0;
    button {
      width: 100%;
      color: #fff;
      border-radius: 8px;
      text-align: left;
      display: block;
      padding: 10px 14px;
      border: none;
      outline: none;
      :hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }
`;

export default OtcExchange;
