import React, { useState, useEffect } from "react";
import Web3 from "web3";
import {
  BASE_API_HOST,
  ZERO_ADDRESS,
  contractAddresses,
  contractAddressesChainWise,
  currencyAddresses,
  nativeTokenList,
  supportedStableCoins,
} from "../constants";
import { metamask_connection } from "../Service/metamask_function";
import {
  fetchCurrencyPrices,
  getBalance,
  getBalanceOfTokens,
  getTokenAllowance,
  getTokenBalance,
  getTokenPrice,
  getVestedTokens,
} from "../Service/otc_function";
const CACHE_KEY = "IS_DARK";

const GlobalContext = React.createContext({
  walletAddressTest: null,
  tokenList: null,
});

const GlobalContextProvider = ({ children }) => {
  const [isWalletConnected, setWalletConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const [shortWalletAddress, setShortWalletAddress] = useState(null);
  const [walletAddressTest, setWalletAddressTest] = useState(null);
  const [tokenList, setTokenList] = useState(null);
  const [dsUsers, setDSUsers] = useState(null);
  const [currencyPrices, setCurrencyPrices] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [linkToTransfer, setLinkToTransfer] = useState(false);
  const [transferToken, setTransferToken] = useState(null);

  useEffect(() => {
    const fetchFunctions = async () => {
      const currencyPricesRes = await fetchCurrencyPrices();
      setCurrencyPrices(currencyPricesRes);
      await metamask_connection(localStorage.getItem("account"), "ahrefClick");
      await updateWalletDetails();
      await fetchTokenList();
      setInterval(async () => {
        await updateWalletDetails();
      }, 500);
    };
    fetchFunctions();
  }, []);

  useEffect(() => {
    const fetchToken = async () => {
      await fetchTokenList();
    };
    fetchToken();
  }, [walletAddress]);

  const fetchTokenList = async () => {
    await fetch(BASE_API_HOST + "/getTokenList")
      .then((res) => res.json())
      .then(
        async (result) => {
          let tokenListLocal = result.result.tokenList.filter(
            (token) => token.chainId !== 820
          );
          setDSUsers(result.result.uniqueSellers);
          let tokenListUpdate = {};
          let tokenListString = "";
          let overallTime = Date.now();

          tokenListUpdate[1] = {};
          tokenListUpdate[56] = {};
          tokenListUpdate[137] = {};
          tokenListUpdate[8453] = {};
          // tokenListUpdate[820] = {};

          Object.entries(supportedStableCoins).forEach(async (entry) => {
            const [key, value] = entry;
            Object.entries(value).forEach(async (entryInside) => {
              const [keyInside, valueInside] = entryInside;
              tokenListUpdate[key][keyInside] = valueInside;
              tokenListUpdate[key][keyInside]["price"] =
                currencyPrices !== null
                  ? currencyPrices[valueInside.symbol]
                  : 0;
              tokenListUpdate[key][keyInside]["mainBalance"] = walletAddress
                ? Web3.utils.fromWei(
                    await getTokenBalance({
                      token: tokenListUpdate[key][keyInside].token,
                      user: walletAddress,
                      chainId: tokenListUpdate[key][keyInside].chainId,
                    })
                  )
                : "0";
              tokenListUpdate[key][keyInside]["allowance"] = walletAddress
                ? Web3.utils.fromWei(
                    await getTokenAllowance({
                      token: tokenListUpdate[key][keyInside].token,
                      owner: walletAddress,
                      spender:
                        contractAddressesChainWise[
                          Web3.utils.toHex(
                            tokenListUpdate[key][keyInside].chainId
                          )
                        ].DUMPERSHIELD_FACTORY_ADDRESS,
                      chainId: tokenListUpdate[key][keyInside].chainId,
                    })
                  )
                : "0";
              tokenListUpdate[key][keyInside]["mainBalanceUSD"] = (
                Number(tokenListUpdate[key][keyInside]["mainBalance"]) *
                Number(tokenListUpdate[key][keyInside]["price"])
              ).toString();
            });
          });

          tokenListUpdate[1][ZERO_ADDRESS] = nativeTokenList[1];
          tokenListUpdate[1][ZERO_ADDRESS]["price"] = currencyPrices
            ? currencyPrices["ETH"]
            : 0;
          tokenListUpdate[1][ZERO_ADDRESS]["mainBalance"] = walletAddress
            ? Web3.utils.fromWei(await getBalance(walletAddress, 1))
            : "0";
          tokenListUpdate[1][ZERO_ADDRESS]["mainBalanceUSD"] = (
            Number(tokenListUpdate[1][ZERO_ADDRESS]["mainBalance"]) *
            Number(tokenListUpdate[1][ZERO_ADDRESS]["price"])
          ).toString();

          tokenListUpdate[56][ZERO_ADDRESS] = nativeTokenList[56];
          tokenListUpdate[56][ZERO_ADDRESS]["price"] =
            currencyPrices !== null ? currencyPrices["BNB"] : 0;
          tokenListUpdate[56][ZERO_ADDRESS]["mainBalance"] = walletAddress
            ? Web3.utils.fromWei(await getBalance(walletAddress, 56))
            : "0";
          tokenListUpdate[56][ZERO_ADDRESS]["mainBalanceUSD"] = (
            Number(tokenListUpdate[56][ZERO_ADDRESS]["mainBalance"]) *
            Number(tokenListUpdate[56][ZERO_ADDRESS]["price"])
          ).toString();

          tokenListUpdate[137][ZERO_ADDRESS] = nativeTokenList[137];
          tokenListUpdate[137][ZERO_ADDRESS]["price"] =
            currencyPrices !== null ? currencyPrices["MATIC"] : 0;
          tokenListUpdate[137][ZERO_ADDRESS]["mainBalance"] = walletAddress
            ? Web3.utils.fromWei(await getBalance(walletAddress, 137))
            : "0";
          tokenListUpdate[137][ZERO_ADDRESS]["mainBalanceUSD"] = (
            Number(tokenListUpdate[137][ZERO_ADDRESS]["mainBalance"]) *
            Number(tokenListUpdate[137][ZERO_ADDRESS]["price"])
          ).toString();

          tokenListUpdate[8453][ZERO_ADDRESS] = nativeTokenList[8453];
          tokenListUpdate[8453][ZERO_ADDRESS]["price"] =
            currencyPrices !== null ? currencyPrices["BASE"] : 0;
          tokenListUpdate[8453][ZERO_ADDRESS]["mainBalance"] = walletAddress
            ? Web3.utils.fromWei(await getBalance(walletAddress, 8453))
            : "0";
          tokenListUpdate[8453][ZERO_ADDRESS]["mainBalanceUSD"] = (
            Number(tokenListUpdate[8453][ZERO_ADDRESS]["mainBalance"]) *
            Number(tokenListUpdate[8453][ZERO_ADDRESS]["price"])
          ).toString();

          for (let i = 0; i < tokenListLocal.length; i++) {
            tokenListUpdate[tokenListLocal[i].chainId][
              tokenListLocal[i].token
            ] = tokenListLocal[i];
            let customTest = Date.now();
            let [tokenBalance, mainBalance, allowance, tokenPrice] =
              walletAddress
                ? await Promise.all([
                    getBalanceOfTokens({
                      token: tokenListLocal[i].token,
                      user: walletAddress,
                      chainId: tokenListLocal[i].chainId,
                    }),
                    getTokenBalance({
                      token: tokenListLocal[i].token,
                      user: walletAddress,
                      chainId: tokenListLocal[i].chainId,
                    }),
                    getTokenAllowance({
                      token: tokenListLocal[i].token,
                      owner: walletAddress,
                      spender:
                        contractAddressesChainWise[
                          Web3.utils.toHex(tokenListLocal[i].chainId)
                        ].DUMPERSHIELD_FACTORY_ADDRESS,
                      chainId: tokenListLocal[i].chainId,
                    }),
                    getTokenPrice(tokenListLocal[i]),
                  ])
                : ["0", "0", "0", "0"];
            // console.log(tokenPrice)
            let customTestTime = Date.now() - customTest;
            // console.log("customTest time  : " + customTestTime + " milliseconds");
            tokenListUpdate[tokenListLocal[i].chainId][tokenListLocal[i].token][
              "mainBalance"
            ] = mainBalance;
            tokenListUpdate[tokenListLocal[i].chainId][tokenListLocal[i].token][
              "allowance"
            ] = allowance;
            tokenListUpdate[tokenListLocal[i].chainId][tokenListLocal[i].token][
              "price"
            ] =
              currencyPrices !== null
                ? tokenPrice * Number(currencyPrices[tokenListLocal[i].chainId])
                : 0;
            tokenListUpdate[tokenListLocal[i].chainId][tokenListLocal[i].token][
              "balances"
            ] = tokenBalance;
            tokenListUpdate[tokenListLocal[i].chainId][tokenListLocal[i].token][
              "canRedeem"
            ] =
              Number(tokenListLocal[i].dsUnlockDate + "000") <
              new Date().getTime();
            tokenListUpdate[tokenListLocal[i].chainId][tokenListLocal[i].token][
              "canDeposit"
            ] = mainBalance > 0;
            tokenListUpdate[tokenListLocal[i].chainId][tokenListLocal[i].token][
              "canTransfer"
            ] = tokenBalance[0] > 0;
            tokenListString = tokenListString + tokenListLocal[i].token + ",";
          }
          let timeTakenOverAll = Date.now() - overallTime;
          tokenListUpdate[56][currencyAddresses.DEGEN]["price"] =
            currencyPrices !== null ? currencyPrices["DEGEN"] : 0;
          setTokenList(tokenListUpdate);
        },
        (error) => {}
      );
  };

  const updateWalletDetails = () => {
    let walletAddress = localStorage.getItem("account");
    let chainId = localStorage.getItem("chainId");
    // let walletAddress = "0x084374b068Eb3db504178b4909eDC26D01226a80";
    if (walletAddress) {
      setWalletConnected(true);
      setWalletAddress(walletAddress);
      setWalletAddressTest(walletAddress);
      const acc =
        walletAddress.substr(0, 6) +
        "..." +
        walletAddress.substr(walletAddress.length - 4);
      setShortWalletAddress(acc);
    }
    if (chainId) {
      setChainId(chainId);
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        isWalletConnected,
        walletAddress,
        chainId,
        shortWalletAddress,
        updateWalletDetails,
        fetchTokenList,
        walletAddressTest,
        tokenList,
        currencyPrices,
        dsUsers,
        linkToTransfer,
        setLinkToTransfer,
        transferToken,
        setTransferToken,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalContextProvider };
