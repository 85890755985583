import React, { Component, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Gs from "../Theme/globalStyles";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import LeftMBX from "../Component/leftBar";
import DumperShieldStatus from "../Component/popup/dumperShieldStatus";
import Collapse from "@kunukn/react-collapse";
import Media from "../Theme/media-breackpoint";
import { Scrollbars } from "react-custom-scrollbars-2";
import DateIcon from "../Assets/images/date.png";
import ArrowRight from "../Assets/images/arrow-right.png";
import {
  checkMinimumRequiredTokens,
  createBallot,
  getABIDecode,
  getABIEncode,
  getRules,
  getTotalRules,
  govVote,
} from "../Service/governance_function";
import { GlobalContext } from "../Context/globalContext";
import {
  BASE_API_HOST,
  CHAINS,
  explorerLink,
  ZERO_ADDRESS,
} from "../constants";
import Web3 from "web3";
import ReactLoaderSpinner from "react-loader-spinner";
import {
  floatOnly,
  intOnly,
  validAddressOnly,
} from "../Service/general_function";
import notificationConfig from "../config/notificationConfig";
import CountdownTimer from "../Component/elements/countDown";
import {
  changeNetwork,
  metamask_connection,
} from "../Service/metamask_function";

function Governance(props) {
  const [index, setIndex] = useState(1);
  const [rulesInfo, setRulesInfo] = useState([
    {
      description: "Set duration of voting for proposal",
      functionName: "setVotingDuration",
      parameters: "(address,uint256)",
      voting_description: "duration of voting for proposal",
    },
    {
      description: "Set minimal level of holdings to create proposal",
      functionName: "setMinimalLevel",
      parameters: "(address,uint256)",
      voting_description: "minimal level of holdings to create proposal",
    },
    {
      description: "Set Router",
      functionName: "setRouter",
      parameters: "(address,address)",
      voting_description: "router address",
    },
    {
      description: "Set date when token will be unlocked from DumperShield",
      functionName: "setUnlockDate",
      parameters: "(address,uint256)",
      voting_description: "dumper shield protection period",
    },
    {
      description: "Set Limits",
      functionName: "setLimits",
      parameters: "(address,uint256,uint256)",
      voting_description: "limits",
    },
    {
      description: "Set address of token's DAO contract if exist",
      functionName: "setDAO",
      parameters: "(address,address)",
      voting_description: "address of token's DAO contract if exist",
    },
    {
      description: "Set Gateway",
      functionName: "setGateway",
      parameters: "(address)",
      voting_description: "",
    },
    {
      description: "Set PDO",
      functionName: "setPDO",
      parameters: "(address)",
      voting_description: "",
    },
  ]);
  const [account, setAccount] = useState(null);
  const [dropDown, setDropDown] = useState(false);
  const [popup01, setPopup01] = useState(false);
  const [chainSelected, setChainSelected] = useState(null);
  const [token, setToken] = useState(ZERO_ADDRESS);
  const [createBallotLoading, setCreateBallotLoading] = useState(false);
  const [voteLoading, setVoteLoading] = useState(false);
  const [tokensSymbolToTransfer, setTokensSymbolToTransfer] =
    useState("Select Token...");
  const [tokenListLoading, setTokenListLoading] = useState(true);
  const [tokenList, setTokenList] = useState(null);
  const [rulesLoading, setRulesLoading] = useState(true);
  const [addressParam, setAddressParam] = useState(null);
  const [intParam, setIntParam] = useState(0);
  const [selectedRules, setSelectedRules] = useState(-1);
  const [rules, setRules] = useState(null);
  const [pendingBallotsLoading, setPendingBallotsLoading] = useState(true);
  const [completedBallotsLoading, setCompletedBallotsLoading] = useState(true);
  const [allBallots, setAllBallots] = useState([]);
  const [pendingBallots, setPendingBallots] = useState([]);
  const [completedBallots, setCompletedBallots] = useState([]);
  const globalContext = useContext(GlobalContext);

  useEffect(async () => {
    if (globalContext.isWalletConnected) {
      await setAccount(globalContext.walletAddress);
    }
  }, [globalContext.walletAddress]);

  useEffect(async () => {
    if (globalContext.tokenList !== null) {
      await setTokenList(globalContext.tokenList);
      await setTokenListLoading(false);
    }
  }, [tokenList, globalContext.tokenList]);

  useEffect(async () => {
    if (tokenList !== null) {
      await fetchBallots();
    }
  }, [tokenList]);

  const getProposalDetail = async (ballot) => {
    let { ruleId, chainId, args } = ballot;
    let decodedParams = await getABIDecode({
      chainId: chainId,
      paramsType: rulesInfo[ruleId].parameters,
      args: args,
    });
    if (ruleId === "0") {
      let token = await tokenList[chainId][
        decodedParams[0][0].toLocaleLowerCase()
      ].symbol;
      return (
        token +
        " " +
        rulesInfo[ruleId].voting_description +
        " " +
        Number(decodedParams[0][1]) / 3600 +
        " Hours"
      );
    } else if (ruleId === "1") {
      let token = await tokenList[chainId][
        decodedParams[0][0].toLocaleLowerCase()
      ].symbol;
      return (
        token +
        " " +
        rulesInfo[ruleId].voting_description +
        " " +
        decodedParams[0][1] +
        " Percentage"
      );
    } else if (ruleId === "2") {
      let token = await tokenList[chainId][
        decodedParams[0][0].toLocaleLowerCase()
      ].symbol;
      return (
        token +
        " " +
        rulesInfo[ruleId].voting_description +
        " " +
        decodedParams[0][1]
      );
    } else if (ruleId === "3") {
      let token = await tokenList[chainId][
        decodedParams[0][0].toLocaleLowerCase()
      ].symbol;
      return (
        token +
        " " +
        rulesInfo[ruleId].voting_description +
        " " +
        new Date(Number(decodedParams[0][1] + "000")).toDateString()
      );
    } else if (ruleId === "4") {
      let token = await tokenList[chainId][
        decodedParams[0][0].toLocaleLowerCase()
      ].symbol;
      return token + " " + rulesInfo[ruleId].voting_description;
    } else if (ruleId === "5") {
      let token = await tokenList[chainId][
        decodedParams[0][0].toLocaleLowerCase()
      ].symbol;
      return (
        token +
        " " +
        rulesInfo[ruleId].voting_description +
        " " +
        decodedParams[0][1]
      );
    } else if (ruleId === "6") {
      return "";
    } else if (ruleId === "7") {
      return "";
    }
  };

  const fetchBallots = async () => {
    await fetch(BASE_API_HOST + "/govRoute/getAllBallots")
      .then((res) => res.json())
      .then(async (result) => {
        await setAllBallots(result.result);
      });
  };

  useEffect(async () => {
    if (allBallots.length > 0) {
      await checkBallots();
    }
  }, [allBallots]);

  const checkBallots = async () => {
    let pendingBallotsLcl = [];
    let completedBallotsLcl = [];
    for (var i = 0; i < allBallots.length; i++) {
      let ele = allBallots[i];
      let proposal = await getProposalDetail(ele);
      let timeToVote = ele.closeVote + "000"; // "1722407058000"
      if (ele.status === "0") {
        pendingBallotsLcl.push({
          proposal: proposal,
          timeToVote: timeToVote,
          ballotId: ele.ballotId,
        });
      } else if (ele.status === "1") {
        let votingPercentage = Number(
          (Number(ele.yea) / Number(ele.totalVotes)) * 100
        ).toFixed(2);
        let negativeVotes = Number(100 - votingPercentage).toFixed(2);
        completedBallotsLcl.push({
          proposal: proposal,
          status: "Approved",
          timeToVote: timeToVote,
          details: { yea: votingPercentage, nay: negativeVotes },
          totalParticipant: Number(
            (Number(ele.totalVotes) / 10 ** 18).toFixed(2)
          ).toLocaleString(),
          voteDate: new Date(Number(ele.closeVote + "000")).toDateString(),
          proof: ele.transactionHashApplyBallot,
          chainId: ele.chainId,
          ballotId: ele.ballotId,
        });
      }
    }
    await setPendingBallots(pendingBallotsLcl.toReversed());
    await setCompletedBallots(completedBallotsLcl.toReversed());
    await setPendingBallotsLoading(false);
    await setCompletedBallotsLoading(false);
  };

  useEffect(() => {
    async function getRulesInternal() {
      await setRules(await getRules(chainSelected));
      await setRulesLoading(false);
    }
    if (chainSelected !== null) {
      getRulesInternal();
    }
  }, [chainSelected]);

  const handleAddress = async () => {
    if (
      selectedRules === 2 ||
      selectedRules === 5 ||
      selectedRules === 6 ||
      selectedRules === 7
    ) {
      if (!(await validAddressOnly(addressParam))) {
        await setAddressParam("");
        notificationConfig.warning("Invalid Address!");
      }
    }
  };

  const handleCreateBallot = async () => {
    if (chainSelected === null || chainSelected === "" || !chainSelected) {
      notificationConfig.warning("Chain not selected!");
      return;
    }

    if (
      tokensSymbolToTransfer === null ||
      tokensSymbolToTransfer === "" ||
      !tokensSymbolToTransfer ||
      tokensSymbolToTransfer === "Select Token..."
    ) {
      notificationConfig.warning("Token not selected!");
      return;
    }

    if (
      selectedRules === null ||
      selectedRules === "" ||
      selectedRules === -1
    ) {
      notificationConfig.warning("Proposal not selected!");
      return;
    }

    if (
      selectedRules === 2 ||
      selectedRules === 5 ||
      selectedRules === 6 ||
      selectedRules === 7
    ) {
      if (!(await validAddressOnly(addressParam))) {
        await setAddressParam("");
        notificationConfig.warning("Invalid Address!");
        return;
      }
    }
    if (
      selectedRules === 0 ||
      selectedRules === 1 ||
      selectedRules === 3 ||
      selectedRules === 4
    ) {
      if (!intParam || intParam === 0 || intParam === null || intParam === "") {
        await setIntParam(0);
        notificationConfig.warning("Invalid inputs!");
        return;
      }
      if (selectedRules === 1 && (intParam < 0 || intParam > 100)) {
        await setIntParam(0);
        notificationConfig.warning("Percentage must be between 0 to 100!");
        return;
      }
    }

    let requiredTokens = await checkMinimumRequiredTokens(chainSelected, token);

    if (
      tokenList[Web3.utils.toDecimal(chainSelected)][token].balances[0] <
      requiredTokens
    ) {
      notificationConfig.warning(
        "You does not have enough token balance to create proposal!"
      );
      return;
    }

    setCreateBallotLoading(true);

    let paramsLcl = "";
    if (
      selectedRules === 0 ||
      selectedRules === 1 ||
      selectedRules === 3 ||
      selectedRules === 4
    ) {
      if (selectedRules === 0) {
        paramsLcl = [token, intParam * 3600];
      } else if (selectedRules === 3) {
        let newDate = new Date()
          .setDate(new Date().getDate() + Number(intParam))
          .toString()
          .slice(0, 10);
        paramsLcl = [token, newDate];
      } else {
        paramsLcl = [token, intParam];
      }
    }
    if (selectedRules === 2 || selectedRules === 5) {
      paramsLcl = [token, addressParam];
    }
    if (selectedRules === 6 || selectedRules === 7) {
      paramsLcl = [addressParam];
    }
    console.log(paramsLcl);
    let args = await getABIEncode({
      chainId: chainSelected,
      functionSignature: rules[selectedRules].funcAbi,
      params: paramsLcl,
    });

    let createBallotData = {};

    createBallotData["token"] = token;
    createBallotData["ruleId"] = selectedRules;
    createBallotData["args"] = args;

    await createBallot(
      createBallotData,
      (hash) => {},
      async (receipt) => {
        setCreateBallotLoading(false);
      },
      async (error) => {
        setCreateBallotLoading(false);
        if (error.code === 4001) {
          notificationConfig.error("Transaction rejected!");
        }
      }
    );
  };

  const handleVote = async (ballotId, vote) => {
    console.log(ballotId, vote);
    if (ballotId === null || ballotId === "" || !ballotId) {
      notificationConfig.warning("Invalid voting proposal!");
      return;
    }

    setVoteLoading(true);

    let createVoteData = {};

    createVoteData["_ballotId"] = ballotId;
    createVoteData["yea"] = vote;

    await govVote(
      createVoteData,
      (hash) => {},
      async (receipt) => {
        console.log("receipt");
        console.log(receipt);
        notificationConfig.success("Vote Given Successfully!");
        setVoteLoading(false);
      },
      async (error) => {
        setVoteLoading(false);
        if (error.code === 4001) {
          notificationConfig.error("Transaction rejected!");
        }
      }
    );
  };

  const onToggle = (indexNew) => setIndex(index === indexNew ? null : indexNew);
  const onToggle02 = (dropDownNew) =>
    setDropDown(dropDown === dropDownNew ? null : dropDownNew);

  return (
    <>
      <MainContainer>
        <LeftMBX />
        <RightContainer>
          <DSTitle01 className="wow fadeInUp" data-wow-delay="0.1s">
            {" "}
            Governance{" "}
          </DSTitle01>
          <DSTitle02 className="wow fadeInUp" data-wow-delay="0.1s">
            {/* Dumper Shield protection period */}
            <div className="linkBX01">
              {" "}
              <a href="javascript:void(0);">About protection period</a> |{" "}
              <a
                href="javascript:void(0);"
                onClick={() => {
                  setPopup01(true);
                }}
              >
                Status
              </a>
            </div>
          </DSTitle02>
          {/* Chain Selection Start */}
          <FormMBX01 className="wow fadeInRight" data-wow-delay="0.1s">
            <FormSbxLabel className="L1">
              Chain on you want to create proposal
            </FormSbxLabel>
            <FormSbxContent>
              <DDMenu onClick={() => onToggle02(1)}>
                {chainSelected !== null
                  ? CHAINS[chainSelected]
                  : "Select Network..."}
                <div className="DDContainer">
                  <Collapse
                    className={"collapse " + (dropDown === 1 ? "active" : "")}
                    isOpen={dropDown === 1}
                    onChange={({ state }) => {}}
                    onInit={({ state }) => {}}
                  >
                    <div className="DDSubContainer">
                      {!tokenListLoading ? (
                        Object.entries(CHAINS).map((ele, key) => {
                          return (
                            <button
                              key={key}
                              onClick={() => {
                                setChainSelected(ele[0]);
                                setToken(ZERO_ADDRESS);
                                setTokensSymbolToTransfer("Select Token...");
                              }}
                            >
                              {ele[1]}
                            </button>
                          );
                        })
                      ) : (
                        <ReactLoaderSpinner
                          type="Bars"
                          color="#fff"
                          height={20}
                          width={40}
                        />
                      )}

                      {/* <button>BNB</button>
                                        <button>ETH</button>
                                        <button>FTM</button> */}
                    </div>
                  </Collapse>
                </div>
              </DDMenu>
            </FormSbxContent>
          </FormMBX01>
          {/* Chain Selection End */}
          {/* Token Selection Start */}
          <FormMBX01 className="wow fadeInRight" data-wow-delay="0.1s">
            <FormSbxLabel className="L1">Token symbol</FormSbxLabel>
            <FormSbxContent>
              <DDMenu onClick={() => onToggle02(2)}>
                {tokensSymbolToTransfer}
                <div className="DDContainer">
                  <Collapse
                    className={"collapse " + (dropDown === 2 ? "active" : "")}
                    isOpen={dropDown === 2}
                    onChange={({ state }) => {}}
                    onInit={({ state }) => {}}
                  >
                    <div className="DDSubContainer">
                      {!tokenListLoading && chainSelected !== null ? (
                        Object.entries(
                          tokenList[Web3.utils.toDecimal(chainSelected)]
                        ).map((ele, key) => {
                          // if(ele[1].canTransfer)
                          return (
                            <button
                              key={key}
                              onClick={() => {
                                setToken(ele[1].token);
                                setTokensSymbolToTransfer(ele[1].symbol);
                              }}
                            >
                              {ele[1].symbol}
                            </button>
                          );
                        })
                      ) : (
                        <ReactLoaderSpinner
                          type="Bars"
                          color="#fff"
                          height={20}
                          width={40}
                        />
                      )}

                      {/* <button>BNB</button>
                                            <button>ETH</button>
                                            <button>FTM</button> */}
                    </div>
                  </Collapse>
                </div>
              </DDMenu>
            </FormSbxContent>
          </FormMBX01>
          {/* Token Selection End */}
          {/* Proposal Selection Start */}
          <FormMBX01 className="wow fadeInRight" data-wow-delay="0.1s">
            <FormSbxLabel className="L1">Choose proposal</FormSbxLabel>
            <FormSbxContent>
              <DDMenu onClick={() => onToggle02(3)}>
                {!rulesLoading && rules !== null && selectedRules !== -1
                  ? rulesInfo[selectedRules].description
                  : "Select Proposal..."}
                <div className="DDContainer">
                  <Collapse
                    className={"collapse " + (dropDown === 3 ? "active" : "")}
                    isOpen={dropDown === 3}
                    onChange={({ state }) => {}}
                    onInit={({ state }) => {}}
                  >
                    <div className="DDSubContainer">
                      {!tokenListLoading &&
                      chainSelected !== null &&
                      rules !== null &&
                      rules.length > 0 ? (
                        rules.map((ele, key) => {
                          // if(ele[1].canTransfer)
                          if (key === 2 || key === 4) {
                            return (
                              <button
                                key={key}
                                style={{
                                  cursor: "none",
                                  opacity: "20%",
                                  pointerEvents: "none",
                                }}
                              >
                                {rulesInfo[key].description}
                              </button>
                            );
                          } else {
                            return (
                              <button
                                key={key}
                                onClick={() => {
                                  setSelectedRules(key);
                                }}
                              >
                                {rulesInfo[key].description}
                              </button>
                            );
                          }
                        })
                      ) : (
                        <ReactLoaderSpinner
                          type="Bars"
                          color="#fff"
                          height={20}
                          width={40}
                        />
                      )}
                    </div>
                  </Collapse>
                </div>
              </DDMenu>
            </FormSbxContent>
          </FormMBX01>
          {/* Proposal Selection End */}

          {selectedRules === 0 ? (
            <FormMBX01 className="wow fadeInRight" data-wow-delay="0.1s">
              <FormSbxLabel className="L1">
                Voting duration{" "}
                <span data-tip="Comming Soon" className="helpIco">
                  <i className="fas fa-question-circle"></i>
                </span>
              </FormSbxLabel>
              <FormSbxContent>
                <DDMenu className="formBox">
                  <span className="badge01">HOURS</span>
                  <input
                    type="text"
                    onKeyDown={intOnly}
                    value={intParam}
                    onChange={(event) => setIntParam(event.target.value)}
                  />
                </DDMenu>
              </FormSbxContent>
            </FormMBX01>
          ) : selectedRules === 1 ? (
            <FormMBX01 className="wow fadeInRight" data-wow-delay="0.1s">
              <FormSbxLabel className="L1">
                Minimum voting power{" "}
                <span data-tip="Comming Soon" className="helpIco">
                  <i className="fas fa-question-circle"></i>
                </span>
              </FormSbxLabel>
              <FormSbxContent>
                <DDMenu className="formBox">
                  <span className="badge01">%</span>
                  <input
                    type="text"
                    onKeyDown={floatOnly}
                    value={intParam}
                    onChange={(event) => setIntParam(event.target.value)}
                  />
                </DDMenu>
              </FormSbxContent>
            </FormMBX01>
          ) : selectedRules === 2 ? (
            <FormMBX01 className="wow fadeInRight" data-wow-delay="0.1s">
              <FormSbxLabel className="L1">
                Router address{" "}
                <span data-tip="Comming Soon" className="helpIco">
                  <i className="fas fa-question-circle"></i>
                </span>
              </FormSbxLabel>
              <FormSbxContent>
                <DDMenu className="formBox">
                  <input
                    type="text"
                    value={addressParam}
                    onBlur={handleAddress}
                    onChange={(event) => setAddressParam(event.target.value)}
                  />
                </DDMenu>
              </FormSbxContent>
            </FormMBX01>
          ) : selectedRules === 3 ? (
            <FormMBX01 className="wow fadeInRight" data-wow-delay="0.1s">
              <FormSbxLabel className="L1">
                Protection period{" "}
                <span data-tip="Comming Soon" className="helpIco">
                  <i className="fas fa-question-circle"></i>
                </span>
              </FormSbxLabel>
              <FormSbxContent>
                <DDMenu className="formBox">
                  <input
                    type="text"
                    onKeyDown={intOnly}
                    value={intParam}
                    onChange={(event) => setIntParam(event.target.value)}
                  />
                  <span className="badge01">DAYS</span>
                </DDMenu>
              </FormSbxContent>
            </FormMBX01>
          ) : selectedRules === 4 ? (
            <FormMBX01 className="wow fadeInRight" data-wow-delay="0.1s">
              <FormSbxLabel className="L1">
                Gateway period limit{" "}
                <span data-tip="Comming Soon" className="helpIco">
                  <i className="fas fa-question-circle"></i>
                </span>
              </FormSbxLabel>
              <FormSbxContent>
                <DDMenu className="formBox">
                  <input
                    type="text"
                    onKeyDown={intOnly}
                    value={intParam}
                    onChange={(event) => setIntParam(event.target.value)}
                  />
                  <span className="badge01">HOURS</span>
                </DDMenu>
              </FormSbxContent>
            </FormMBX01>
          ) : selectedRules === 5 ? (
            <FormMBX01 className="wow fadeInRight" data-wow-delay="0.1s">
              <FormSbxLabel className="L1">
                DAO smart contract address{" "}
                <span data-tip="Comming Soon" className="helpIco">
                  <i className="fas fa-question-circle"></i>
                </span>
              </FormSbxLabel>
              <FormSbxContent>
                <DDMenu className="formBox">
                  <input
                    type="text"
                    value={addressParam}
                    onBlur={handleAddress}
                    onChange={(event) => setAddressParam(event.target.value)}
                  />
                </DDMenu>
              </FormSbxContent>
            </FormMBX01>
          ) : selectedRules === 6 ? (
            <FormMBX01 className="wow fadeInRight" data-wow-delay="0.1s">
              <FormSbxLabel className="L1">
                Gateway smart contract address{" "}
                <span data-tip="Comming Soon" className="helpIco">
                  <i className="fas fa-question-circle"></i>
                </span>
              </FormSbxLabel>
              <FormSbxContent>
                <DDMenu className="formBox">
                  <input
                    type="text"
                    value={addressParam}
                    onBlur={handleAddress}
                    onChange={(event) => setAddressParam(event.target.value)}
                  />
                </DDMenu>
              </FormSbxContent>
            </FormMBX01>
          ) : selectedRules === 7 ? (
            <FormMBX01 className="wow fadeInRight" data-wow-delay="0.1s">
              <FormSbxLabel className="L1">
                PDO smart contract address{" "}
                <span data-tip="Comming Soon" className="helpIco">
                  <i className="fas fa-question-circle"></i>
                </span>
              </FormSbxLabel>
              <FormSbxContent>
                <DDMenu className="formBox">
                  <input
                    type="text"
                    value={addressParam}
                    onBlur={handleAddress}
                    onChange={(event) => setAddressParam(event.target.value)}
                  />
                </DDMenu>
              </FormSbxContent>
            </FormMBX01>
          ) : null}

          <FormMBX01 className="wow fadeInRight" data-wow-delay="0.3s">
            <FormSbxLabel> </FormSbxLabel>
            <FormSbxContent>
              {account !== null ? (
                createBallotLoading ? (
                  <button
                    className="PlaOrBTN01"
                    onClick={() => handleCreateBallot()}
                  >
                    <ReactLoaderSpinner
                      type="Bars"
                      color="#fff"
                      height={30}
                      width={100}
                    />
                  </button>
                ) : chainSelected !== null &&
                  Web3.utils.toHex(chainSelected) !== globalContext.chainId ? (
                  <button
                    className="PlaOrBTN01"
                    onClick={() => {
                      changeNetwork(Web3.utils.toHex(chainSelected));
                    }}
                  >
                    CHANGE NETWORK
                  </button>
                ) : (
                  <button
                    className="PlaOrBTN01"
                    onClick={() => handleCreateBallot()}
                  >
                    CREATE A PROPOSAL
                  </button>
                )
              ) : (
                <button
                  className="PlaOrBTN01"
                  onClick={() => {
                    metamask_connection(
                      localStorage.getItem("account"),
                      "ahrefClick"
                    );
                  }}
                >
                  CONNECT WALLET
                </button>
              )}
            </FormSbxContent>
          </FormMBX01>
          <AddNewChanel>
            <a href="#">Add new liquidity channel</a> {/* This will be popup */}
          </AddNewChanel>

          <BorderTop />
          {/* <FormMBX01 className="wow fadeInRight" data-wow-delay="0.2s">
                          <FormSbxLabel className='L1'>Change dumper shield protection period </FormSbxLabel>
                          <FormSbxContent className='v2'>
                              <DDMenu className="formBox v2">
                                  <img src={DateIcon} alt="" />
                                  <input type="text" />
                              </DDMenu>
                              <img src={ArrowRight} alt="" className='arrow-div' />
                              <DDMenu className="formBox v2">
                                  <img src={DateIcon} alt="" />
                                  <input type="text" />
                              </DDMenu>
                          </FormSbxContent> 
                          <FormSbxContent >
                              <DDMenu style={{width:"45%"}} className="formBox">
                                  <span className="badge01">DAYS</span>
                                  <input type="text" />
                              </DDMenu>
                              <DDMenu style={{width:"45%"}} className="formBox">
                                  <span className="badge01">DAYS</span>
                                  <input type="text" />
                              </DDMenu>
                          </FormSbxContent> 
                      </FormMBX01> */}

          <PlacOrdMBX>
            <DSTitle01 className="wow fadeInRight v1" data-wow-delay="0.1s">
              Active Proposal{" "}
            </DSTitle01>
            <Scrollbars
              className="custom-scroll"
              autoHeight
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              autoHeightMin={340}
              autoHeightMax={340}
              renderTrackHorizontal={(props) => (
                <div {...props} className="track-horizontal" />
              )}
              renderTrackVertical={(props) => (
                <div {...props} className="track-vertical" />
              )}
              renderThumbHorizontal={(props) => (
                <div {...props} className="thumb-horizontal" />
              )}
              renderThumbVertical={(props) => (
                <div {...props} className="thumb-vertical" />
              )}
              renderView={(props) => <div {...props} className="view" />}
            >
              <OpfyTableBX className="wow fadeInRight" data-wow-delay="0.2s">
                <table width="100%" border="0" cellSpacing="0" cellPadding="20">
                  <tbody>
                    <tr>
                      <th width="65%" align="left" valign="middle" scope="col">
                        Proposal
                      </th>
                      <th align="left" valign="middle" scope="col">
                        Time to Vote
                      </th>
                      <th align="center" valign="middle" scope="col">
                        Choose your vote
                      </th>
                    </tr>
                    {pendingBallotsLoading ? (
                      <tr align="left" valign="middle">
                        <td colSpan="3" align="middle">
                          <ReactLoaderSpinner
                            type="Bars"
                            color="#fff"
                            height={45}
                            width={130}
                          />
                        </td>
                      </tr>
                    ) : pendingBallots.length > 0 ? (
                      pendingBallots.map((ele, key) => {
                        return (
                          <tr align="left" valign="middle">
                            <td>
                              <div className="tableTitle01">
                                {ele.proposal}
                                <span>
                                  End{" "}
                                  {new Date(
                                    Number(ele.timeToVote)
                                  ).toDateString()}
                                </span>
                              </div>
                            </td>
                            <td>
                              <CountdownTimer targetTime={ele.timeToVote} />
                            </td>
                            <td>
                              {voteLoading ? (
                                <div className="tableBTN">
                                  <ReactLoaderSpinner
                                    type="Bars"
                                    color="#fff"
                                    height={40}
                                    width={110}
                                  />
                                </div>
                              ) : (
                                <div className="tableBTN">
                                  <button
                                    className="tbBTN01"
                                    onClick={() =>
                                      handleVote(ele.ballotId, false)
                                    }
                                  >
                                    Nay
                                  </button>
                                  <button
                                    className="tbBTN02"
                                    onClick={() =>
                                      handleVote(ele.ballotId, true)
                                    }
                                  >
                                    Yea
                                  </button>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr align="left" valign="middle">
                        <td colSpan="3" align="middle">
                          No any active proposal
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </OpfyTableBX>
            </Scrollbars>
          </PlacOrdMBX>
          <BorderTop />

          <PlacOrdMBX className="wow fadeInRight" data-wow-delay="0.2s">
            <DSTitle01 className="v2">
              Completed Votes History <i className="fas fa-sort-up"></i>
            </DSTitle01>
            <Scrollbars
              className="custom-scroll"
              autoHeight
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              autoHeightMin={340}
              autoHeightMax={340}
              renderTrackHorizontal={(props) => (
                <div {...props} className="track-horizontal" />
              )}
              renderTrackVertical={(props) => (
                <div {...props} className="track-vertical" />
              )}
              renderThumbHorizontal={(props) => (
                <div {...props} className="thumb-horizontal" />
              )}
              renderThumbVertical={(props) => (
                <div {...props} className="thumb-vertical" />
              )}
              renderView={(props) => <div {...props} className="view" />}
            >
              <OpfyTableBX className="wow fadeInRight" data-wow-delay="0.2s">
                <table width="100%" border="0" cellSpacing="0" cellPadding="20">
                  <tbody>
                    <tr>
                      <th width="29%" align="left" valign="middle" scope="col">
                        Proposal
                      </th>
                      <th width="13%" align="left" valign="middle" scope="col">
                        Status
                      </th>
                      <th width="13%" align="left" valign="middle" scope="col">
                        Details
                      </th>
                      <th width="13%" align="left" valign="middle" scope="col">
                        Total Participant
                      </th>
                      <th width="20%" align="left" valign="middle" scope="col">
                        Vote date
                      </th>
                      <th width="12%" align="left" valign="middle" scope="col">
                        Proof
                      </th>
                    </tr>
                    {completedBallotsLoading ? (
                      <tr align="left" valign="middle">
                        <td colSpan="6" align="middle">
                          <ReactLoaderSpinner
                            type="Bars"
                            color="#fff"
                            height={45}
                            width={130}
                          />
                        </td>
                      </tr>
                    ) : completedBallots.length > 0 ? (
                      completedBallots.map((ele, key) => {
                        return (
                          <tr align="left" valign="middle">
                            <td>{ele.proposal}</td>
                            <td
                              className={
                                ele.status === "Approved"
                                  ? "color-green"
                                  : "color-red"
                              }
                            >
                              {ele.status}
                            </td>
                            <td>
                              <span>Yea: {ele.details.yea}%</span>{" "}
                              <span>Nay:{ele.details.nay}%</span>
                            </td>
                            <td>{ele.totalParticipant}</td>
                            <td>{ele.voteDate}</td>
                            <td>
                              <a
                                className="color02"
                                target="_blank"
                                href={
                                  explorerLink[ele.chainId] + "/tx/" + ele.proof
                                }
                              >
                                {ele.proof.slice(0, 6) +
                                  "..." +
                                  ele.proof.slice(58, 64)}
                              </a>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr align="left" valign="middle">
                        <td colSpan="6" align="middle">
                          No any completed proposal
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </OpfyTableBX>
            </Scrollbars>
            {/* <FormMBX01> 
                              <FormSbxLabel>Remove JNTR Transferring Rules.</FormSbxLabel>
                              <FormSbxContent className="darkTxt">Arguments: 0, Status: Approved, Participants said: Yea: 100% Nay:0% </FormSbxContent>
                          </FormMBX01>
                          <FormMBX01>
                              <FormSbxLabel>Add new rule.. </FormSbxLabel>
                              <FormSbxContent className="darkTxt">Arguments: 0x546c04414d1e102A175ab2C41B522F0f924ec229, 90,0,0,0, withdwarFund(uint256,address), Status: Approved, Participants said: Yea: 100% Nay:0%</FormSbxContent>
                          </FormMBX01>
                          <FormMBX01>
                              <FormSbxLabel>Upgrade Liquidity contract.</FormSbxLabel>
                              <FormSbxContent className="darkTxt">Arguments: 2, Status: Approved, Participants said: Yea: 100% Nay:0%</FormSbxContent>
                          </FormMBX01>
                          <FormMBX01>
                              <FormSbxLabel> Withdraw from Side Reserve.</FormSbxLabel>
                              <FormSbxContent className="darkTxt">Arguments: 90000000000000000000, 0xc326DF3Bec90f94887d2756E03B51a222F2b0de4, Status: Approved, Participants said: Yea: 100% Nay:0%</FormSbxContent>
                          </FormMBX01>
                          <FormMBX01>
                              <FormSbxLabel>setfundWalletRatio (9% Real Estate wallet ratio in contribution value).</FormSbxLabel>
                              <FormSbxContent className="darkTxt">Arguments: 0, Status: Approved, Participants said: Yea: 100% Nay:0% </FormSbxContent>
                          </FormMBX01>
                          <FormMBX01>
                              <FormSbxLabel>Max daily investment.</FormSbxLabel>
                              <FormSbxContent className="darkTxt">Arguments: 1000, Status: Approved, Participants said: Yea: 100% Nay:0%</FormSbxContent>
                          </FormMBX01>
                          <FormMBX01>
                              <FormSbxLabel> Daily Appreciation Limit. </FormSbxLabel>
                              <FormSbxContent className="darkTxt">Arguments: 1000, Status: Approved, Participants said: Yea: 100% Nay:0%</FormSbxContent>
                          </FormMBX01>
                          <FormMBX01>
                              <FormSbxLabel> setfundWalletRatio (9% Real Estate wallet ratio in contribution value).</FormSbxLabel>
                              <FormSbxContent className="darkTxt">Arguments: 1, Status: Approved, Participants said: Yea: 100% Nay:0%</FormSbxContent>
                          </FormMBX01>
                          <FormMBX01>
                              <FormSbxLabel>Side reserve allocation. </FormSbxLabel>
                              <FormSbxContent className="darkTxt">Arguments: 10, Status: Approved, Participants said: Yea: 100% Nay:0%</FormSbxContent>
                          </FormMBX01>
                          <FormMBX01>
                              <FormSbxLabel> Upgrade Whitelist contract.</FormSbxLabel>
                              <FormSbxContent className="darkTxt">Arguments: 5, Status: Approved, Participants said: Yea: 100% Nay:0%</FormSbxContent>
                          </FormMBX01>
                          <FormMBX01>
                              <FormSbxLabel>Open / close BuyBack.</FormSbxLabel>
                              <FormSbxContent className="darkTxt">Arguments: true, Status: Approved, Participants said: Yea: 100% Nay:0%</FormSbxContent>
                          </FormMBX01>
                          <FormMBX01>
                              <FormSbxLabel>Upgrade Whitelist contract.</FormSbxLabel>
                              <FormSbxContent className="darkTxt">Arguments: 6, Status: Approved, Participants said: Yea: 100% Nay:0%</FormSbxContent>
                          </FormMBX01>
                          <FormMBX01>
                              <FormSbxLabel>Upgrade Whitelist contract.</FormSbxLabel>
                              <FormSbxContent className="darkTxt">Arguments: 7, Status: Approved, Participants said: Yea: 100% Nay:0%</FormSbxContent>
                          </FormMBX01>
                          <FormMBX01>
                              <FormSbxLabel>Upgrade Liquidity contract.</FormSbxLabel>
                              <FormSbxContent className="darkTxt">Arguments: 3, Status: Approved, Participants said: Yea: 100% Nay:0%</FormSbxContent>
                          </FormMBX01>
                          <FormMBX01>
                              <FormSbxLabel>Add new rule.. </FormSbxLabel>
                              <FormSbxContent className="darkTxt">Arguments: 0x546c04414d1e102A175ab2C41B522F0f924ec229, 90,0,0,0, setRdemptionDayRelay(uint256), Status: Approved, Participants said: Yea: 100% Nay:0%</FormSbxContent>
                          </FormMBX01>
                          <FormMBX01>
                              <FormSbxLabel>set Redemption Day Delay.</FormSbxLabel>
                              <FormSbxContent className="darkTxt">Arguments: 7, Status: Approved, Participants said: Yea: 100% Nay:0%</FormSbxContent>
                          </FormMBX01>
                          <FormMBX01>
                              <FormSbxLabel>Side reserve allocation.</FormSbxLabel>
                              <FormSbxContent className="darkTxt">Arguments: 99, Status: Approved, Participants said: Yea: 100% Nay:0%</FormSbxContent>
                          </FormMBX01>
                          <FormMBX01>
                              <FormSbxLabel>Daily Appreciation Limit.</FormSbxLabel>
                              <FormSbxContent className="darkTxt">Arguments: 1, Status: Approved, Participants said: Yea: 100% Nay:0</FormSbxContent>
                          </FormMBX01>
                          <FormMBX01>
                              <FormSbxLabel>Daily Appreciation Limit.</FormSbxLabel>
                              <FormSbxContent className="darkTxt">Arguments: 101, Status: Approved, Participants said: Yea: 100% Nay:0%</FormSbxContent>
                          </FormMBX01>*/}
          </PlacOrdMBX>
        </RightContainer>
      </MainContainer>

      <DumperShieldStatus
        isOpen={popup01}
        dismiss={() => {
          setPopup01(false);
        }}
      />
      <ReactTooltip effect="solid" className="myTip" />
    </>
  );
}

// Common Style Div

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const AddNewChanel = styled.div`
  margin: 10px 0 0 0;
  a {
    font-size: 12px;
    color: #00f02b;
  }
`;

const MainContainer = styled(FlexDiv)`
  width: 100%;
  align-items: stretch;
  justify-content: flex-start;
`;

const RightContainer = styled(FlexDiv)`
  width: calc(100% - 82px);
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 56px;
  align-content: baseline;
  ${Media.md} {
    padding: 25px 20px;
  }
  ${Media.sm} {
    width: 100%;
  }
`;

const FormMBX01 = styled(FlexDiv)`
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 20px;
  ${Media.md} {
    display: block;
  }
`;

const FormSbxLabel = styled(FlexDiv)`
  width: 30%;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 700;
  ${Media.md} {
    width: 100%;
  }
  &.L1 {
    ${Media.md} {
      margin-bottom: 15px;
    }
  }
`;

const FormSbxContent = styled(FlexDiv)`
  width: 70%;
  justify-content: flex-start;
  ${Media.md} {
    width: 100%;
  }
  .arrow-div {
    ${Media.md} {
      margin: 0 auto 20px;
      transform: rotate(90deg);
    }
  }
  &.v2 {
    justify-content: space-between;
  }
  &.darkTxt {
    color: #9a9a9a;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 18px;
    word-break: break-all;
  }
  .md-radio label {
    font-size: 16px;
    color: #fff;
    font-weight: 400;
  }
  .md-radio label:after {
    background-color: #00f02b;
  }
  .md-radio label:before,
  .md-radio input[type="radio"]:checked + label:before {
    border: 2px solid #5a5e67;
    left: -2px;
    top: -2px;
    width: 24px;
    height: 24px;
  }
  .PlaOrBTN01 {
    min-width: 344px;
    font-size: 16px;
    font-weight: 700;
    height: 60px;
    background: none;
    color: #fff;
    border-radius: 10px;
    border: 2px solid #00f02b;
    box-shadow: 0 0 15px rgba(0, 240, 43, 0.7);
    border-radius: 10px;
    :hover {
      background-color: #0dd732;
    }
    ${Media.xs} {
      min-width: 100%;
    }
  }
`;

const DDMenu = styled(FlexDiv)`
  justify-content: flex-start;
  position: relative;
  height: 60px;
  border-radius: 10px;
  border: 2px solid #5a5e67;
  font-size: 16px;
  font-weight: 400;
  padding: 0 15px;
  i {
    max-width: 80px;
    margin-right: 10px;
  }
  :after {
    content: "";
    border-width: 7px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    right: 16px;
    top: 25px;
  }
  &.formBox {
    position: relative;
    input {
      width: 100%;
      height: 52px;
      padding: 6px 0;
      background-color: transparent;
      border: 0px;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }
    :after {
      display: none;
    }
    .badge01 {
      background-color: #5a5e67;
      color: #fff;
      font-size: 15px;
      font-weight: 700;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      min-width: 120px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    ${Media.md} {
      width: 100% !important;
      margin-bottom: 15px;
    }
    &.v2 {
      width: calc(50% - 33px);
      img {
        position: absolute;
        top: 18px;
        right: 20px;
      }
    }
  }
  &.noBorder {
    :after {
      display: none;
    }
    border: 0px;
  }
  .fas {
    position: absolute;
    right: 8px;
    top: 19px;
    font-size: 18px;
  }
  .DDContainer {
    width: 100%;
    z-index: 10;
    position: absolute;
    left: 0;
    top: calc(100% + 6px);
  }
  .DDSubContainer {
    width: 100%;
    color: white;
    border-radius: 10px;
    background-color: rgba(41, 42, 48, 0.95);
    padding: 10px;
    margin: 0 0 8px 0;
    button {
      width: 100%;
      color: #fff;
      border-radius: 8px;
      text-align: left;
      display: block;
      padding: 10px 14px;
      border: none;
      outline: none;
      :hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }
`;

const DSTabLinkBX = styled(FlexDiv)`
  justify-content: flex-start;

  button {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    margin-right: 35px;
    padding: 0;
    :hover,
    &.active {
      color: #00f02b;
    }
  }
`;

const DSTitle01 = styled(FlexDiv)`
  justify-content: space-between;
  font: 700 36px/40px "Kanit", arial;
  color: #00f02b;
  &.v2 {
    margin: 10px 0 40px 0;
  }
  .DSrPart {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    font-weight: 700;
  }
  ${Media.xs} {
    font: 700 30px/34px "Kanit", arial;
    display: block;
  }
  .linkBX01 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #5a5e67;
    font-weight: 300;
    a {
      color: #00f02b;
      margin: 0 8px;
      font-family: "IBM Plex Mono", Arial, sans-serif;
      font-weight: 700;
      :hover {
        text-decoration: underline;
      }
      :first-child {
        ${Media.md} {
          margin: 0px 8px 0px 0px;
        }
      }
    }
    ${Media.md} {
      justify-content: flex-start;
      margin-top: 15px;
    }
  }
  &.v1 {
    margin: 0px 0px 50px;
    ${Media.md} {
      margin: 0px 0px 30px;
    }
  }
  i {
    margin-left: auto;
    font-size: 21px;
    color: #858686;
  }
`;

const DSTitle02 = styled(FlexDiv)`
  justify-content: flex-start;
  font: 700 21px/40px "IBM Plex Mono", arial;
  color: #ffffff;
  margin: 11px 0 18px;
  .linkBX01 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #5a5e67;
    font-weight: 300;
    margin-left: auto;
    a {
      color: #00f02b;
      margin: 0 8px;
      font-family: "IBM Plex Mono", Arial, sans-serif;
      font-weight: 700;
      :hover {
        text-decoration: underline;
      }
      :first-child {
        ${Media.md} {
          margin: 0px 8px 0px 0px;
        }
      }
    }
    ${Media.md} {
      justify-content: flex-start;
    }
    ${Media.md} {
      justify-content: flex-start;
      margin-top: 15px;
    }
  }
  ${Media.md} {
    display: block;
    margin-top: 40px;
  }
`;

const TabNav = styled(FlexDiv)`
  padding-top: 50px;
  flex-direction: row;
  .tab-Link {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    width: 33.33%;
    text-align: center;
    border-bottom: 3px solid #393d46;
    padding-bottom: 30px;
    position: relative;
    i {
      position: absolute;
      top: -3px;
      margin-left: 3px;
      i {
        font-size: 14px;
        color: #000;
        width: 23px;
        height: 23px;
        background-color: #00f02b;
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    }
  }
  .tab-Link.active {
    color: #00f02b;
    border-bottom-color: #00f02b;
  }
`;
const OpfyTableBX = styled(FlexDiv)`
  margin: 0px;
  font-size: 14px;
  color: #fff;
  justify-content: flex-start;
  p {
    color: #00f02b;
    font-size: 12px;
    margin-top: 15px;
    text-align: left;
    width: 100%;
  }
  .sortLink {
    position: absolute;
    right: 12px;
    color: #9a9a9a;
    top: 18px;
    font-size: 18px;
    :hover {
      color: #fff;
    }
  }
  .deleteBTN {
    background: transparent;
    border: none;
    font-size: 24px;
    color: #53575d;
    margin: 0 auto;
    :hover {
      color: #c32b2d;
    }
  }
  .OpfyBTNbar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 210px;

    &.v2 {
      width: 180px;
      justify-content: center;
    }

    .btn01 {
      width: 155px;
      font-weight: 700;
      height: 40px;
      border: 2px solid #00f02b;
      border-radius: 5px;
      color: #00f02b;
      :hover {
        color: #000;
        background-color: #00f02b;
      }
    }
    .btn02 {
      color: #858686;
      padding: 10px;
      margin-left: 6px;
      :hover {
        color: #c32b2d;
      }
    }
  }
  table {
    min-width: max-content;
    width: 100%;
    border: 1px solid #1c1f25;
    background: #010202;
  }
  table tr td {
    border: 1px solid #1c1f25;
    padding: 22px 20px;
    &:nth-last-child(01) {
      border-right: 0px;
      width: 212px;
    }
    &:nth-child(01) {
      border-left: 0px;
      a {
        color: #4b4bff;
        :hover {
          text-decoration: underline;
        }
      }
    }
    &.bor01 {
      border-right: 5px solid #1c1f25;
    }
    & a.color02 {
      color: #3131ff;
    }
    span {
      margin: 0 15px 0 0;
    }
  }
  table tr th {
    border: 2px solid #1c1f25;
    color: #9a9a9a;
    position: relative;
    padding: 17px 20px;
    &:nth-last-child(01) {
      border-right: 0px;
    }
    &:nth-child(01) {
      border-left: 0px;
    }
    &.bor01 {
      border-right: 5px solid #1c1f25;
    }
  }
  .color-green {
    color: #00f02b;
  }
  .color-red {
    color: #c32b2d;
  }
  table tr:hover td {
    background-color: #0b0c0f;
  }

  .tableTitle01 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    span {
      color: #9a9a9a;
      font-size: 12px;
      font-weight: 400;
      padding: 6px 0;
    }
  }

  .tableTimer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    span {
      width: 25px;
      height: 33px;
      border: 1px solid #5a5e67;
      border-radius: 3px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 3px;
    }
  }

  .tableBTN {
    width: 235px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tbBTN01 {
    width: 109px;
    height: 40px;
    background: none;
    color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    border: 2px solid #c32b2d;
    box-shadow: 0 0 6px #c32b2d;
    &:hover {
      background: #c32b2d;
    }
  }
  .tbBTN02 {
    width: 109px;
    height: 40px;
    background: none;
    color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    border: 2px solid #00f02b;
    box-shadow: 0 0 6px #00f02b;
    &:hover {
      background: #00f02b;
    }
  }
`;

const PlacOrdMBX = styled(FlexDiv)`
  padding: 0 0 0;
  color: #fff;
  position: relative;
  ${Media.md} {
    padding-bottom: 0px;
  }
`;
const BorderTop = styled.div`
  border-top: 1px solid #1e2127;
  display: table;
  width: calc(100% + 190px);
  max-width: inherit;
  margin: 78px -55px 80px -55px;
  ${Media.md} {
    margin: 60px -20px 50px -20px;
  }
`;
const InfoRight = styled.div`
  font-size: 12px;
  color: #9a9a9a;
  text-align: right;
  width: 100%;
  margin: 11px 0 0 0;
`;

export default Governance;
